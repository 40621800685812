import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { stopLoading, startLoading} from 'app/store/utilSlice';
import sortComparer from 'app/shared-components/util/lastMessageSortComparer';
import { isUnread } from 'app/shared-components/utils';
import { setChannelRead, setChannelUnread, addChannel } from './channelsSlice';
import { bulkApiGet, buildGetItem } from 'app/shared-components/util/bulkApi';
import { apiGet, apiPut, apiDel, apiPost } from 'app/shared-components/util/restAPI';

const emptyArray= [];



const orgAdapter = createEntityAdapter({
});



export const fetchOrganizationProfile = createAsyncThunk(
  "org/fetchOrganizationProfile",
  async (id, { dispatch, getState }) => {
	try{
	  dispatch(startLoading());
	  let url= '/channelentity/' + id + '/public/orgprofile';
	  const apiName = 'PublicAPI';
	  const path = url;
	  const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };
	
	  let data= await apiGet(apiName, path, options);
	  return {...data, id: data.organization.channelId};
	}finally{
		dispatch(stopLoading());
	}
  }
)

export const fetchOrganizationReferrals = createAsyncThunk(
  "org/fetchOrganizationReferrals",
  async ({orgChannelId, status }, { dispatch, getState }) => {
	try{
	  dispatch(startLoading());
	  let url= '/channel/' + orgChannelId + '/organizationreferral/pairs?status=STARTS:' + status;
	  const apiName = 'CoreAPI';
	  const path = url;
	  const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };
	
	  let data= await apiGet(apiName, path, options);
	  return {...data, id: data.organization.channelId};
	}finally{
		dispatch(stopLoading());
	}
  }
)



const organizationsSlice = createSlice({
  name: "organizations",
  initialState: { profiles: orgAdapter.getInitialState() },  
  reducers: {
  },
  extraReducers: builder => {
    builder
    .addCase(fetchOrganizationProfile.fulfilled, (state, action) => {
        orgAdapter.setOne(state.profiles, action.payload);
    })
  }
})


const { selectById: selectProfileById} = orgAdapter.getSelectors(state => state.organizations.profiles);


export const selectOrganizationProfileById = (channelId) => state => {
	if (channelId) return selectProfileById(state, channelId);
	else return null;
}


export default organizationsSlice.reducer;

import lazyLoad from 'app/shared-components/util/lazyLoad';
const ProgramProfile = lazyLoad(() => import('app/shared-components/organization/ProgramProfile'));
const LocationProfile = lazyLoad(() => import('app/shared-components/organization/LocationProfile'));
const OrganizationProfile = lazyLoad(() => import('app/shared-components/organization/OrganizationProfile'));


const PublicProgramProfileConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: true,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: null, //anyone
  routes: [
	{
      path: '/public/organization/:organizationId',
      element: <OrganizationProfile />,
    },
    {
      path: '/public/program/:programId',
      element: <ProgramProfile />,
    },
    {
      path: '/public/location/:locationId',
      element: <LocationProfile />,
    }
  ],
};

export default PublicProgramProfileConfig;



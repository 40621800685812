import lazyLoad from 'app/shared-components/util/lazyLoad';

const OrganizationsApp = lazyLoad(() => import('./OrganizationsApp'));
const OrganizationApp = lazyLoad(() => import('./organization/OrganizationApp'));
const OrganizationForm = lazyLoad(() => import('./organization/OrganizationForm'));
const OrganizationPrograms = lazyLoad(() => import('./organization/OrganizationPrograms'));
const OrganizationLocations = lazyLoad(() => import('./organization/OrganizationLocations'));
const OrganizationReferrals = lazyLoad(() => import('./organization/OrganizationReferrals'));
const ChannelAdmin = lazyLoad(() => import('app/shared-components/channel/channelApp/ChannelAdmin'));
const ChannelMembers = lazyLoad(() => import('app/shared-components/channel/channelApp/ChannelMembers'));
const ChannelFiles = lazyLoad(() => import('app/shared-components/channel/channelApp/ChannelFiles'));
const ChannelAppChannel = lazyLoad(() => import('app/shared-components/channel/channelApp/ChannelAppChannel'));
const ChannelAppThreads = lazyLoad(() => import('app/shared-components/channel/channelApp/ChannelAppThreads'));
const TopicList = lazyLoad(() => import('app/shared-components/channel/TopicList'));


const OrganizationsAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'apps/organizations',
      element: <OrganizationsApp />,
      children: [
        {
          path: ':id',
          element: <OrganizationForm />,
        },
        {
          path: 'invites',
          element: <div/>,
        }
      ],
    },
    {
      path: 'apps/organization/:groupId',
      element: <OrganizationApp />,
      children: [
        {
          path: '',
          element: <ChannelAppChannel type="organization"/>,
        },
        {
          path: 'searchresults',
          element: <ChannelAppChannel type="organization"/>,
        },
        {
          path: 'channel/:channelId',
          element: <ChannelAppChannel type="organization"/>,
        },
        {
          path: 'channel/:channelId/searchresults',
          element: <ChannelAppChannel type="organization"/>,
        },
        {
          path: 'programs',
          element: <OrganizationPrograms/>,
        },
        {
          path: 'program/:programId',
          element: <OrganizationPrograms/>,
        },
        {
          path: 'program/:programId/locations',
          element: <OrganizationPrograms/>,
        },
        {
          path: 'locations',
          element: <OrganizationLocations/>,
        },
        {
          path: 'location/:locationId',
          element: <OrganizationLocations/>,
        },
        {
          path: 'location/:locationId/programs',
          element: <OrganizationLocations/>,
        },
        {
          path: 'referrals',
          element: <OrganizationReferrals/>,
        },
        {
          path: 'referrals/:tab',
          element: <OrganizationReferrals/>,
        },
        {
          path: 'referral/:referralId',
          element: <OrganizationReferrals/>,
        },
        {
          path: 'topics',
          element: <TopicList type="organization"/>,
        },
        {
          path: 'threads',
          element: <ChannelAppThreads type="organization"/>,
        },
        {
          path: 'topic/:topicId',
          element: <TopicList type="organization"/>,
        },
        {
          path: 'files',
          element: <ChannelFiles type="organization" />,
        },
        {
          path: 'files/view/:fileId',
          element: <ChannelFiles type="organization" />,
        },
        {
          path: 'files/:folderId',
          element: <ChannelFiles type="organization" />,
        },
        {
          path: 'files/:folderId/view/:fileId',
          element: <ChannelFiles type="organization" />,
        },
        {
          path: 'admin',
          element: <ChannelAdmin type="organization" formElement={OrganizationForm}/>,
        },
        {
          path: 'members',
          element: <ChannelMembers type="organization"/>,
        },
        {
          path: 'member/:memberId',
          element: <ChannelMembers type="organization"/>,
        },
        {
          path: 'result/:userId',
          element: <ChannelMembers type="organization"/>,
        },
      ],
    },
  ],
};

export default OrganizationsAppConfig;

import { Icon } from '@mui/material';
import Button from 'app/shared-components/marketing/Button';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { fetchTestimonials, selectTestimonials } from 'app/store/marketing/testimonialsSlice';
import { useEffect, useRef, useState } from 'react';
import TestimonialPreview from 'app/shared-components/marketing/TestimonialPreview';
import TextBubble from 'app/shared-components/marketing/TextBubble';
import HeaderTags from 'app/shared-components/helmet/HeaderTags';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import { showMessage } from 'app/store/fuse/messageSlice';
import {  startLoading, stopLoading} from 'app/store/utilSlice'; 
import { phoneRegex, formatDate } from'app/shared-components/utils';
import { errorMessage } from'app/shared-components/util/message';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import ControlledTextField from 'app/shared-components/form/ControlledTextField';
import ControlledCheckbox from 'app/shared-components/form/ControlledCheckbox';
import DefaultButton from 'app/shared-components/layout/DefaultButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { motion } from 'framer-motion';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ControlledSingleSelect from 'app/shared-components/form/ControlledSingleSelect';
import FeatureGrid from 'app/shared-components/marketing/FeatureGrid';
import PreviewSlider from 'app/shared-components/marketing/PreviewSlider';
import ImageView from 'app/shared-components/marketing/ImageView';
import Lightbox from "yet-another-react-lightbox";


/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  email: yup.string().email('You must enter a valid email').required('You must enter a email'),
  firstName: yup.string().required('You must enter your first name'),
  lastName: yup.string().required('You must enter your last name'),
  professional: yup.bool(),
  agree: yup.bool().oneOf([true], 'You must agree to proceed.'),
});

const defaultValues = {
  email: '',
  firstName: '',
  lastName: '',
  professional: false,
  agree: false,
};

const SLIDER_PROPS = {
  infinite: true,
  slidesToShow: 2,
  slidesToScroll: 5,
};

const screenshots = [
  {
    src: '/assets/images/marketing/screenshots/group.jpg',
    alternativeText: 'Group Collaboration',
  },
  {
    src: '/assets/images/marketing/screenshots/referrals.png',
    alternativeText: 'Structured Referrals',
  },
  {
    src: '/assets/images/marketing/screenshots/referral_chat.png',
    alternativeText: 'Secure Referral Collaboration',
  },
  {
    src: '/assets/images/marketing/screenshots/profile.jpg',
    alternativeText: 'Professional Profile',
  },
  {
    src: '/assets/images/marketing/screenshots/team.jpg',
    alternativeText: 'Team Collaboration',
  }
];

const INFO = [
  {
    title: 'How It Works',
    body: 'We’ve already raised over $100,000 from key investors and forward-thinking care providers. We’re crowd funding through the WeFunder platform - which means anyone can invest as little as $100. You don’t need to be an accredited investor. You just need to believe in what we’re doing as much as we do - and if we win, you win.',
    icon: 'help'
  },
  {
    title: 'Next Steps',
    body: 'By entering your details below you are not committing to any investment - it will simply allow us to send you some important information about the crowd funding campaign, after which you can make sure you’re comfortable before investing in the future of what we’re doing.',
    icon: 'check-circle'
  }
];


const features = [
  {
    id: 0,
    title: 'Searchable Profile',
    body: 'Customize your professional profile to showcase your background, areas of expertise, credentials, and more. Be easily searchable by peers and patients for efficient referrals and networking.',
    icon: 'search'
  },
  {
    id: 1,
    title: 'Referral Exchange',
    body: 'Create comprehensive referrals searchable by members, and find patients that fit your expertise. Simplify the referral process and match patients with the right provider seamlessly.',
    icon: 'swap_horiz'
  },
  {
    id: 2,
    title: 'Connect and Share',
    body: 'Find other mental health professionals to connect and share with. Create, join, and network in discussion groups tailored for providers of mental health services.',
    icon: 'share'
  },
  {
    id: 3,
    title: 'Team Collaboration',
    body: 'Efficiently collaborate with other providers, share information and documents securely, and communicate in real- time. Build stronger care teams and improve patient outcomes.',
    icon: 'chat'
  },
  {
    id: 4,
    title: 'Get Verified',
    body: 'Enter your credentials to be independently verified to enhance your profile, support your experience, promote your expertise, and ensure others that you have the training and education needed to provide relevant care.',
    icon: 'verified'
  },
  {
    id: 5,
    title: 'HIPAA Compliant',
    body: 'Halespring was built from the ground up to be secure and HIPAA-compliant in order to keep all information private and safe. This includes direct messages to clients and other providers, as well as team collaboration channels to share case information.',
    icon: 'lock'
  },
]

function getFeatureBgClasses(i) {
  switch (i % 4) {
    case 0: return { bg: 'bg-hs-blue-pale', icon: 'bg-hs-blue-light' };
    case 2: return { bg: 'bg-hs-green-light', icon: 'bg-hs-green' };
    default: return { bg: 'bg-hs-grey-light', icon: 'bg-hs-grey' }
  }
}



export default function Invest() {
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  const [open, setOpen] = useState(false);

  const { control, formState, handleSubmit, setError, reset } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;
  
  
  function onSubmit({ email, firstName, lastName, professional, amount }) {
	   dispatch(startLoading());
	   
	   const headers= {
          headers: {
              'content-type': 'application/json',
              'revision': '2024-06-15'
          }
     };

	   const body= 
      {
        "data": {
          "type": "subscription",
          "attributes": {
            "custom_source": "Investment Signup Page",
            "profile": {
              "data": {
                "type": "profile",
                "attributes": {
                  "email": email,
                  "first_name": firstName,
                  "last_name": lastName,
                  "properties": {
                    "potentialInvestment": amount,
                    "isProfessional": professional,
                  }
                },
              }
            }
          },
          "relationships": {
            "list": {
              "data": {
                "type": "list",
                "id": "TgxHKJ"
              }
            }
          }
        }
      };
      
    axios.post('https://a.klaviyo.com/client/subscriptions/?company_id=WvFXiq', body, headers)
         .then(resp => setSubmitted(true))
		     .catch(error => errorMessage(dispatch, error.message))
		     .finally(() => dispatch(stopLoading()));

  }

  useEffect(() => {
    dispatch(fetchTestimonials({
      pagination: {
        page: 1,
        pageSize: 3
      },
      sort: ['publishedAt:desc']
    }));
  }, [])
  
  const TermsLabel= (props) => {
     return <Typography>I agree to receive email communications, and to the Halespring
               <Link className="ml-4" to="/pages/privacy" target="_blank">Privacy Policy</Link> 
            </Typography>
  }

  const testimonials = useSelector(selectTestimonials());
  
  const host = window.location.protocol + '//' + window.location.host;
  const orgImage=  host + '/assets/images/logo/HALESPRING-HEADER-191.jpg';

  return (
    <>
      <HeaderTags title="Invest in Halespring: join our journey" 
                  description="Learn about the opportunity to become an investor in Halespring - an online community for the mental health ecosystem, offering essential tools for professionals, patients, and loved ones." 
                  keywords="Investment,Startup,Crowdfunding,Mental Health,Therapist,Therapy,Community,Referrals,Pyschologist,Psychiatrist"
                  image={orgImage}
      />
    <div className="pt-48">
      {/* Hero */}
      <section>
        <div className="container">
          <h1 className="h3 lg:h1 text-center">
            Invest in Halespring
          </h1>

          <p className="body lg:body-lg text-center mt-12 lg:mt-20 mb-24">
          We’re raising money to fund the next stage of the Halespring platform - improving collaboration and patient-provider relationships across the U.S. mental healthcare system. 
          </p>

        </div>
        
        {/* INFO */}
      <section className="bg-white">
        <div className="container bg-white pt-40 pb-40">
          <FeatureGrid features={INFO} />
        </div>
      </section>
        
        {/* WeFunder */}
      <section className="relative bg-hs-blue-pale">
        <div className="absolute z-0 top-0 left-0 w-full h-1/2 bg-hs-white" />

        <div className="lg:container">
          <div className="relative z-10 bg-hs-blue lg:rounded-20  text-center px-16 py-60 lg:pt-92 lg:pb-68 ">
            <h2 className="h2 uppercase text-center text-hs-white">
              Learn more!
            </h2>

            <p className="body mt-20 lg:mt-30 text-hs-white">
              Visit our campaign on WeFunder, an SEC-regulated crowdfunding platform. Learn more about the Halespring investment opportunity, and if you are interested, invest online!
            </p>

            <div className="mt-20 lg:mt-60">
              <Button
                to="https://wefunder.com/halespring"
                className="bg-hs-green text-hs-white"
              >
                Visit Campaign
              </Button>
            </div>
          </div>
        </div>
      </section>
      
        
        
        
        {/* screenshots */}
        <section className="pt-60 pb-60">
          <div className="container">
            <PreviewSlider
              title="Screenshots"
              sliderProps={SLIDER_PROPS}
              moreBtn={{
                title: 'See All',
                onClick: () => setOpen(true)
              }}
            >
              {screenshots.map((object, i) =>
                <ImageView
                  key={i}
                  onClick={() => setOpen(true)}
                  object={object}
                />
              )}
            </PreviewSlider>
            
            <Lightbox
              open={open}
              close={() => setOpen(false)}
              slides={screenshots}
            />
          </div>
        </section>


        <div className="relative mt-12 lg:mt-0">
          <div className="bg-hs-blue-pale absolute bottom-0 left-0 w-full h-1/2 z-0" />

          <div className="container py-40">
            <div className="grid grid-cols-2 lg:grid-cols-4 lg:grid-rows-2 lg:grid-flow-col items-center gap-20 relative z-10">
              {/* <img className="w-full hidden lg:block lg:order-0" src="/assets/images/marketing/providers/early-access.svg" /> */}
              <TextBubble
                className="w-full hidden lg:block lg:order-0"
                shape="onePoint"
                label={['Free', 'Online Profile']}
                shapeClass="rotate-180"
              />
              {/* <img className="w-full order-1 lg:order-1" src="/assets/images/marketing/providers/request.svg" /> */}
              <TextBubble
                className="w-full order-1 lg:order-1"
                shape="twoPoints"
                label={['Build Your', 'Network']}
                shapeClass="fill-hs-green"
              />
              <img className="w-full col-span-2 row-span-2 rounded-20 order-0 lg:order-2" src="/assets/images/marketing/providers/people.jpg" />
              {/* <img className="w-full hidden lg:block lg:order-3" src="/assets/images/marketing/providers/reserve.svg" /> */}
              <TextBubble
                className="w-full hidden lg:block lg:order-3"
                shape="twoPoints"
                label={['HIPAA', 'Compliant']}
                shapeClass="fill-hs-green"
              />
              {/* <img className="w-full order-2 lg:order-4" src="/assets/images/marketing/providers/pros.svg" /> */}
              <TextBubble
                className="w-full order-2 lg:order-4"
                shape="onePoint"
                label={['Professional', 'Referrals']}
              />
            </div>
          </div>
        </div>
      </section>

    

      {/* Testimonials */}
      <section className="bg-hs-blue-pale py-80">
        <div className="container">
          <PreviewSlider
            title="How we help"
          >
            {testimonials.map((testimonial) =>
              <TestimonialPreview
                key={testimonial.id}
                testimonial={testimonial}
              />
            )}
          </PreviewSlider>
        </div>
      </section>
    </div>
    </>
  );
}
import { stopLoading} from 'app/store/utilSlice';
import { apiGet, apiPut, apiDel, apiPost } from 'app/shared-components/util/restAPI';


export const fetchUsers= async (userId, params) => {  
  let url= '/user/' + userId;
  let apiName = 'CoreAPI';

  if (params.type === 'followers') {
    url = url + `/followedby/users`;
  }else if (params.type === 'following') {
    url = url + `/userfollow/users`;
  }else if (params.type === 'suggested') {
    url = '/search/users/suggested';
  }else if (params.type === 'favorite') {
    apiName= 'CoreAPI2';
    url= '/x2/userx/' + userId + '/relationshipstatus/favorites';
  }else if (params.type === 'tagged') {
    apiName= 'CoreAPI2';
    url= '/x2/userx/' + userId + '/taggedusers';
    if (params.tag) url = url + `?tag=` + params.tag;
  }else if (params.type === 'search') {
	  if (params.query) url = `/search/users?query=` + params.query;
	  else return null;
  }else  {
    url = url + `/userconnection/users`;
  }

  const path = url;
  const options = {
	  headers: {},
	  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  let data= await apiGet(apiName, path, options);

  return data;
};

export const searchProviders= async (params) => {  
  let url= '/search/public/providers';
  const apiName = 'PublicAPI';
  const path = url;
  const options = {
	  headers: {},
	  body: params,
	  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  let data= await apiPut(apiName, path, options);
  return data;
};

export const fetchInvites= async (userId, params) => {  
  const apiName = 'CoreAPI';
  const path = '/connectioninvite/pendingbyto/' + userId;
  const options = {
	  headers: {},
	  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };
  let data= await apiGet(apiName, path, options);
  let out= [];
  for (let i in data) {
	const resp= data[i];
	let invite= { ... resp, inviteId: resp.id, id: resp.fromUserId};
    out.push(invite);
  }
  return out;
};

export const fetchOutgoingInvites= async (userId, params) => {  
  const apiName = 'CoreAPI';
  const path = '/connectioninvite/pendingbyfrom/' + userId;
  const options = {
	  headers: {},
	  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };
  let data= await apiGet(apiName, path, options);
  let out= [];
  for (let i in data) {
	const resp= data[i];
	let invite= { ... resp, inviteId: resp.id, id: resp.toUserId};
    out.push(invite);
  }
  return out;
};

export const getUserStatus= async (userId, contactId) => {  
  let url= '/x2/userx/' + userId + '/relationshipstatus/' + contactId;

  const apiName = 'CoreAPI2';
  const path = url;
  const options = {
	  headers: {},
	  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  let data= null;
  try{
	  data= await apiGet(apiName, path, options);
  }catch(e){
	  console.log(e);
  }
  
  return convertStatus(contactId, data);
};

const convertStatus= (contactId, data) => {
     return { id: contactId, details: data ? data.userRelationships : [], tags: data?.tags ? data.tags : [],  isFavorite: data?.favorite ? true : false, notes: data?.notes};
}

export const setFavoriteStatus= async (userId, contactId, isFavorite, notes) => {  
  let url= '/x2/userx/' + userId + '/setrelationshipfavoritestatus';

  const apiName = 'CoreAPI2';
  const path = url;
  const options = {
	  headers: {},
	  body: {targetUserId: contactId, favorite: isFavorite, notes: notes},
	  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  let data= await apiPut(apiName, path, options);
  return convertStatus(contactId, data);
};

export const setUserTags= async (userId, contactId, tags) => {  
  let url= '/x2/userx/' + userId + '/updatetags';

  const apiName = 'CoreAPI2';
  const path = url;
  const options = {
	  headers: {},
	  body: {modelKey: "User:" + contactId, tags: tags},
	  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  let data= await apiPut(apiName, path, options);
  return data;
};

export const getUserConnection= async (userId, contactId) => {  
  let url= '/user/' + userId + '/userconnection/' + contactId;

  const apiName = 'CoreAPI';
  const path = url;
  const options = {
	  headers: {},
	  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  let data= null;
  try{
	  data= await apiGet(apiName, path, options);
  }catch(e){
	  console.log(e);
  }
  return data;
};

export const addUserFollow= async (userId, contactId) => {  
  let url= '/user/' + userId + '/userfollow';

  const apiName = 'CoreAPI';
  const path = url;
  const options = {
	  headers: {},
	  body: {targetUserId: contactId},
	  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  let data= await apiPut(apiName, path, options);
  return data;
};

export const removeUserFollow= async (userId, contactId) => {  
  let url= '/user/' + userId + '/userfollow/' + contactId;

  const apiName = 'CoreAPI';
  const path = url;
  const options = {
	  headers: {},
	  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  let data= await apiDel(apiName, path, options);
  return data;
};

export const addUserConnection= async (userId, contactId) => {  
  let url= '/user/' + userId + '/userconnection';

  const apiName = 'CoreAPI';
  const path = url;
  const options = {
	  headers: {},
	  body: {targetUserId: contactId},
	  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  let data= await apiPut(apiName, path, options);
  return data;
};

export const removeUserConnection= async (userId, contactId) => {  
  let url= '/user/' + userId + '/userconnection/' + contactId;

  const apiName = 'CoreAPI';
  const path = url;
  const options = {
	  headers: {},
	  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  let data= await apiDel(apiName, path, options);
  return data;
};

//Remove connnection invite FROM userId TO contactId
export const removeConnectionInvite= async (userId, contactId) => { 
  const inviteId= userId + "." + contactId;
  const url= '/connectioninvite/' + inviteId; 

  const apiName = 'CoreAPI';
  const path = url;
  const options = {
	  headers: {},
	  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  let data= await apiDel(apiName, path, options);
  return data;
};


export const createInvite= async (userId, contactId, message) => {  
  let url= '/connectioninvite';

  const apiName = 'CoreAPI';
  const path = url;
  const options = {
	  headers: {},
	  body: { fromUserId: userId, toUserId: contactId, message: message},
	  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  let data= await apiPut(apiName, path, options);
  return data;
};

//Updates status of connnection invite FROM contactId TO userId
export const declineInvite= async (userId, contactId) => {  
  const inviteId= contactId + "." + userId;
  let url= '/connectioninvite/' + inviteId;
  const apiName = 'CoreAPI';
  const path = url;
  const options = {
	  headers: {},
	  body: { status: 'rejected'},
	  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  let data= await apiPut(apiName, path, options);
  return data;
};

export const createEmailInvite= async ({email, message, channelId, connect}) => {  
  let url= '/emailinvite';

  const apiName = 'CoreAPI';
  const path = url;
  const options = {
	  headers: {},
	  body: { email: email, message: message, connectToUser: connect, channelId: channelId},
	  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  let data= await apiPut(apiName, path, options);
  return data;
};

export const removeEmailInvite= async (id) => { 
  const url= '/emailinvite/' + id; 
  const apiName = 'CoreAPI';
  const path = url;
  const options = {
	  headers: {},
	  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  let data= await apiDel(apiName, path, options);
  return data;
};

export const fetchEmailInvites= async (ownerKey) => {  
  const apiName = 'CoreAPI';
  const path = '/emailinvite/byowner/' + ownerKey;
  const options = {
	  headers: {},
	  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };
  let data= await apiGet(apiName, path, options);
  return data;
};

export const getUser= async (userId) => {  
  let url= '/user/public/' + userId;

  const apiName = 'PublicAPI';
  const path = url;
  const options = {
	  headers: {},
	  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  let data= await apiGet(apiName, path, options);
  return data;
};

export const adminGetUser= async (userId) => {  
  let url= '/user/' + userId;

  const apiName = 'CoreAPI';
  const path = url;
  const options = {
	  headers: {},
	  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  let data= await apiGet(apiName, path, options);
  return data;
};

export const getUserByScreenName= async (screenName) => {  
  let url= '/x2/userx/public/byscreenname?screenName=' + screenName;

  const apiName = 'PublicAPI2';
  const path = url;
  const options = {
	  headers: {},
	  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  let data= await apiGet(apiName, path, options);
  return data;
};

 export const getProviderInfo = async (providerInfoId) => {
	  const apiName = 'PublicAPI';
	  const path = '/providerinfo/public/' + providerInfoId;
      const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };

	 let data= await apiGet(apiName, path, options);
	 return data;
  };

export const checkScreenName= async (screenName) => {  
  let url= '/x2/userx/public/checkscreenname?screenName=' + screenName;

  const apiName = 'PublicAPI2';
  const path = url;
  const options = {
	  headers: {},
	  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  let data= await apiGet(apiName, path, options);
  return data;
};

export const adminUpdateUser= async (userId, data) => {  
  let url= '/user/' + userId;

  const apiName = 'CoreAPI';
  const path = url;
  const options = {
	  body: data,
	  headers: {},
	  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  let resp= await apiPut(apiName, path, options);
  return resp;
};

export const fetchUserOrgs= async (userId) => {  
  let url= '/x2/userx/' + userId + '/public/orgs';

  const apiName = 'PublicAPI2';
  const path = url;
  const options = {
	  headers: {},
	  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  let data= await apiGet(apiName, path, options);
  return data;
};
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { stopLoading, startLoading} from 'app/store/utilSlice';
import { setChannelRead, setChannelUnread } from './channelsSlice';
import { submitPost } from './postSlice';
import { addDmChannel, addDmChannels, removeDmChannel } from './dmChannelSlice';
import { apiGet, apiPut, apiDel, apiPost } from 'app/shared-components/util/restAPI';
import sortComparer from 'app/shared-components/util/lastMessageSortComparer';
import { isUnread } from 'app/shared-components/utils';


const interestAdapter = createEntityAdapter({
	sortComparer: sortComparer,
});


export const fetchInterests = createAsyncThunk(
  "referralInterest/fetchInterests",
  async ({bulk, status}, { dispatch, getState }) => {
	dispatch(startLoading());
	try{
	  const { user }= getState();
	  let data= bulk;
	  if (!data){
	      const apiName = 'CoreAPI';
		  const path = '/user/' + user.id + '/referralinterest/pairs' + (status ? '?status=STARTS:' + status : "");
	      const options = {
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
		  data= await apiGet(apiName, path, options);
	  }
	  
	  const out= data.map((pair) => combineInterest(pair.interest, pair.referral));
	  dispatch(addDmChannels(out.map((dm) => ({type: 'referralInterest', foreignId: dm.id, channelId: dm.dmChannelId, data: dm}))));
	  return out;
	}finally{
	  dispatch(stopLoading());
	}
  }
);

export const fetchInterest = createAsyncThunk(
  "referralInterest/fetchInterest",
  async ({referralId}, { dispatch, getState }) => {
	dispatch(startLoading());
	try{
	  const { user }= getState();
      const apiName = 'CoreAPI';
	  const path = '/outboundreferral/' + referralId + '/interestpair?userId=' + user.id;
      const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };
	  const data= await apiGet(apiName, path, options);
	  let out= null;
	  if (data.interest && data.referral){
	  	out= combineInterest(data.interest, data.referral);
	  	dispatch(addDmChannel({type: 'referralInterest', foreignId: out.id, channelId: out.dmChannelId, data: out}));
	  } 
	  return out;
	}finally{
	  dispatch(stopLoading());
	}
  }
);

export const createInterest = createAsyncThunk(
  "referralInterest/createInterest",
  async (params, { dispatch, getState }) => {
	  dispatch(startLoading());
	  try{
		  const { user }= getState();
		  const { referralId, referral, message, organizationId }= params;
	      const apiName = 'CoreAPI';
	      const path = '/user/' + user.id + '/referralinterest';
		  const body= {referralId: referralId, userId: user.id, message: message, organizationId: organizationId};
	      const options = {
			  headers: {},
			  body: body,
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		 const data= await apiPut(apiName, path, options);
		 const out=  combineInterest(data, referral);
		 dispatch(addDmChannel({type: 'referralInterest', foreignId: out.id, channelId: out.dmChannelId, data: out}));
		 return out;
	 }finally{
		 dispatch(stopLoading());
	 }
  }
)


export const deleteInterest = createAsyncThunk(
  "referralInterest/deleteInterest",
  async (params, { dispatch, getState }) => {
	  dispatch(startLoading());
	  try{
		  const { user }= getState();
	      const { referralId } = params;
	      const apiName = 'CoreAPI';
	      const path = '/user/' + user.id + '/referralinterest/' + referralId;
	      const options = {
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		 let data= await apiDel(apiName, path, options);
		 dispatch(removeDmChannel(referralId));
		 return data;
	 }finally{
		 dispatch(stopLoading());
	 }
  }
)

export const closeInterest = createAsyncThunk(
  "referralInterest/closeInterest",
  async (params, { dispatch, getState }) => {
	  dispatch(startLoading());
	  try{
		  const { user }= getState();
	      const { referralId } = params;
	      const apiName = 'CoreAPI';
	      const path = '/user/' + user.id + '/referralinterest/' + referralId;
	      const options = {
	      	  body: {status: 'closedinterest'},
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		 let data= await apiPut(apiName, path, options);
		 //dispatch(removeDmChannel(referralId));
		 return data;
	 }finally{
		 dispatch(stopLoading());
	 }
  }
)

const combineInterest= (interest, referral) => {
	return {...referral, ...interest};
}


const findInterestWithChannelId= (state, channelId) => {
	let out= findInterestWithChannelIdFromEntityState(state.open, channelId);
	if (!out) out= findInterestWithChannelIdFromEntityState(state.closed, channelId);
	return out;
}

const findInterestWithChannelIdFromEntityState= (entityState, channelId) => {
	let out= {};
	entityState.ids.map((id) => {
		const interest= entityState.entities[id];
		if (interest && interest.dmChannelId == channelId) out= {interest, entities: entityState};
	})
	return out;
}

const findEntry= (state, referralId) => {
	  let interest= state.open.entities[referralId];
	  if (interest){
	  	  return { entities: state.open, interest: interest };
	  }
	  interest= state.closed.entities[referralId];
	  if (interest){
	  	  return { entities: state.closed, interest: interest };
	  }
      return {};
}


const { selectById: selectOpenById, selectAll: selectAllOpen } = interestAdapter.getSelectors(state => state.referralInterest.open);
const { selectById: selectClosedById, selectAll: selectAllClosed } = interestAdapter.getSelectors(state => state.referralInterest.closed);

const referralInterestSlice = createSlice({
  name: "referralInterest",
  initialState: { 
  	open: interestAdapter.getInitialState({status: 'idle', unread: false}),
  	closed: interestAdapter.getInitialState({status: 'idle', unread: false}),
  	createInterest: null,
	  showDetails: null,
  },
  reducers: {
	  setCreateInterest: (state, action) => {
	      state.createInterest= action.payload;
	  },
	  setShowDetails: (state, action) => {
		  state.showDetails= action.payload;
	  },
	  updateInterest: (state, action) => {
	  	  const interest= action.payload;
	  	  const entry= interest.status == 'open' ? state.open : state.closed;
	      interestAdapter.upsertOne(entry, interest);
	      entry.unread= isUnread(entry.entities);
	  },
  },
  extraReducers: builder => {
    builder
    .addCase(fetchInterests.fulfilled, (state, action) => {
    	const status = action.meta.arg.status;
    	const entry= status == 'open' ? state.open : state.closed;
        interestAdapter.setMany(entry, action.payload);
        entry.status= 'succeeded';
        entry.unread= isUnread(entry.entities);
    })
    .addCase(createInterest.fulfilled, (state, action) => {
        interestAdapter.addOne(state.open, action.payload);
    })
    .addCase(fetchInterest.fulfilled, (state, action) => {
    	if (action.payload){
    		const status = action.payload.status;
    	    const entry= status == 'open' ? state.open : state.closed;
    	    interestAdapter.addOne(entry, action.payload);
    	    entry.unread= isUnread(entry.entities);
    	}
    })
    .addCase(setChannelRead.fulfilled, (state, action) =>  {
        const channelId = action.payload;
        const {interest, entities}= findInterestWithChannelId(state, channelId);
        if (interest) interest.unread= false;
        if (entities) entities.unread= isUnread(entities.entities);
    })
    .addCase(setChannelUnread, (state, action) =>  {
        const channelId = action.payload;
        const {interest, entities}= findInterestWithChannelId(state, channelId);
        if (interest) interest.unread= true;
        if (entities) entities.unread= true;
    })
    .addCase(submitPost.fulfilled, (state, action) => {
      const channelId = action.meta.arg.channelId;
      const {interest, entities}= findInterestWithChannelId(state, channelId);
      if (interest){
		  interest.lastMessageTimestamp= action.payload.createdAt;
	  }
    })
    .addCase(deleteInterest.fulfilled, (state, action) => {
      const referralId = action.meta.arg.referralId;
      const entry= findEntry(state, referralId);
      interestAdapter.removeOne(entry.entities, referralId);
      entry.unread= isUnread(entry.entities);
    })
    .addCase(closeInterest.fulfilled, (state, action) => {
      const referralId = action.meta.arg.referralId;
      const entry= findEntry(state, referralId);
      const interest= entry.interest;
      interest.status= "closedinterest";
      interestAdapter.removeOne(state.open, referralId);
      interestAdapter.addOne(state.closed, interest);
      state.closed.unread= isUnread(state.closed.entities);
      state.open.unread= isUnread(state.open.entities);
    })
  }
})


export const selectInterestById = (referralId) => state => {
	let out= null;
	if (referralId) {
		out= selectOpenById(state, referralId);
		if (out == null) out= selectClosedById(state, referralId);
	}
	return out;
}

export const selectAllOpenInterest = () => state => {
	return selectAllOpen(state);
}

export const selectAllClosedInterest = () => state => {
	return selectAllClosed(state);
}


export const selectOpenInterestUnread = ({ referralInterest }) => referralInterest.open.unread;
export const selectClosedInterestUnread = ({ referralInterest }) => referralInterest.closed.unread;
export const selectCreateInterest = ({ referralInterest }) => referralInterest.createInterest;
export const selectShowDetails = ({ referralInterest }) => referralInterest.showDetails;

export const { setCreateInterest, setShowDetails, updateInterest } = referralInterestSlice.actions;
export default referralInterestSlice.reducer;

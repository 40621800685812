import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { stopLoading, startLoading} from 'app/store/utilSlice';
import sortComparer from 'app/shared-components/util/lastMessageSortComparer';
import { isUnread } from 'app/shared-components/utils';
import { setChannelRead, setChannelUnread, addChannel } from './channelsSlice';
import { bulkApiGet, buildGetItem } from 'app/shared-components/util/bulkApi';
import { apiGet, apiPut, apiDel, apiPost } from 'app/shared-components/util/restAPI';

const emptyArray= [];

const locationAdapter = createEntityAdapter({
});

const orgAdapter = createEntityAdapter({
});

const programAdapter = createEntityAdapter({
});


export const fetchLocation = createAsyncThunk(
  "orgLocations/fetchLocation",
  async (id, { dispatch }) => {
	dispatch(startLoading());
	try{
      const apiName = 'CoreAPI';
	  const path = '/organizationlocation/' + id;
      const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };

	  const data= await apiGet(apiName, path, options);
	  dispatch(fetchProgLocsByLocation({locationId: id}));
	  return data;
	}finally{
	  dispatch(stopLoading());
	}
  }
);

export const fetchLocations = createAsyncThunk(
  "orgLocations/fetchLocations",
  async ({organizationId, bulk}, { dispatch, getState }) => {
	  let data= bulk;
	  if (!data){ //pre-fetched data via bulk api
	      const apiName = 'CoreAPI';
		  const path = '/organizationlocation/byorg/' + organizationId;
	      const options = {
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
		  data= await apiGet(apiName, path, options);
	 }
	 
	 //Use bulk load to get programs for the locations
	 //Do this async so it doesn't block the return of this thunk
	 bulkLocationListCalls({locations: data}).then((bulk) => {
		 const bulkData= bulk.items;
		 for (let i in data) {
			const location= data[i];
			const programs= bulkData[location.id];
			dispatch(fetchProgLocsByLocation({locationId: location.id, bulk: programs.code == 200 ? programs.body : undefined}));
		 }
	 });
	
     return data;
  }
)

export const fetchLocationsPublic = createAsyncThunk(
  "orgLocations/fetchLocationsPublic",
  async ({organizationId}, { dispatch, getState }) => {

      const apiName = 'CoreAPI';
	  const path = '/organizationlocation/byorg/' + organizationId;
      const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };
	  const data= await apiGet(apiName, path, options);

	 
	 //Use bulk load to get programs for the locations
	 //Do this async so it doesn't block the return of this thunk
	 bulkLocationListCalls({locations: data}).then((bulk) => {
		 const bulkData= bulk.items;
		 for (let i in data) {
			const location= data[i];
			const programs= bulkData[location.id];
			dispatch(fetchProgLocsByLocation({locationId: location.id, bulk: programs.code == 200 ? programs.body : undefined}));
		 }
	 });
	
     return data;
  }
)

export const bulkLocationListCalls= async ({locations}) => { 
	let items= [];
	for (let i in locations) {
		const location= locations[i];
		items.push(buildGetItem(location.id, '/organizationlocation/' + location.id + '/programlocation/programs', {id: location.id})); 
	}
	return await bulkApiGet({items: items});
}


export const fetchProgLocsByLocation = createAsyncThunk(
  "programLocations/fetchProgLocsByLocation",
  async ({locationId, bulk}, { dispatch, getState }) => {
	  let data= bulk;
	  if (!data){ //pre-fetched data via bulk api
	      const apiName = 'CoreAPI';
		  const path = '/organizationlocation/' + locationId + "/programlocation/programs";
	      const options = {
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
		  data= await apiGet(apiName, path, options);
	 }
	
     return data;
  }
);


export const createLocation = createAsyncThunk(
  "orgLocations/createLocation",
  async (params, { dispatch, getState }) => {
	  try{
	      const apiName = 'CoreAPI';
		  const path = '/organizationlocation/';
		  let body= { ...params};
	      const options = {
			  headers: {},
			  body: body,
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		 let out= await apiPut(apiName, path, options);
		 return out;
	 }finally{
		 dispatch(stopLoading());
	 }
  }
)

export const updateLocation = createAsyncThunk(
  "orgLocations/updateLocation",
  async (params, { dispatch, getState }) => {
	  try{
	      const apiName = 'CoreAPI';
		  const path = '/organizationlocation/' + params.id;
		  let body= { ...params};
	      const options = {
			  headers: {},
			  body: body,
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		 let data= await apiPut(apiName, path, options);
		 return data;
	 }finally{
		 dispatch(stopLoading());
	 }
  }
)


export const deleteLocation = createAsyncThunk(
  "orgLocations/deleteLocation",
  async (params, { dispatch }) => {
	  try{
	      const { locationId } = params;
	      const apiName = 'CoreAPI';
		  const path = '/organizationlocation/' + locationId;
	      const options = {
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		 let data= await apiDel(apiName, path, options);
		 return data;
	 }finally{
		 dispatch(stopLoading());
	 }
  }
)

export const fetchLocationProfile = createAsyncThunk(
  "orgLocations/fetchLocationProfile",
  async (id, { dispatch, getState }) => {
	try{
	  dispatch(startLoading());
	  let url= '/organizationlocation/' + id + '/public/profile';
	  const apiName = 'PublicAPI';
	  const path = url;
	  const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };
	
	  let data= await apiGet(apiName, path, options);
	  return {...data, id: data.location.id};
	}finally{
		dispatch(stopLoading());
	}
  }
)


const newLocation= (data) => {
	return {...data, programs: programAdapter.getInitialState()};
}

const newOrgEntry= (orgId) => {
	return {id: orgId, locations: locationAdapter.getInitialState()};
}

const orgLocationsSlice = createSlice({
  name: "orgLocations",
  initialState: { all: locationAdapter.getInitialState(),
                  orgMap: orgAdapter.getInitialState(),
                  profiles: locationAdapter.getInitialState(),
                  selectedLocationsTab: 0,
                },  
  reducers: {
    setSelectedLocationsTab: {
      reducer: (state, action) => {
        state.selectedLocationsTab = action.payload;
      }
    },
  },
  extraReducers: builder => {
    builder
    .addCase(fetchLocation.fulfilled, (state, action) => {
		const orgId= action.payload.organizationId;
		const orgEntry= state.orgMap.entities[orgId];
		const location= newLocation(action.payload);
		locationAdapter.addOne(state.all, {id: location.id, organizationId: orgId});
		if (orgEntry){
			locationAdapter.setOne(orgEntry.locations, location);
		}else{
			const newEntry= newOrgEntry(orgId);
			newEntry.locations= locationAdapter.addOne(newEntry.locations, location);
			orgAdapter.addOne(state.orgMap, newEntry);
		}  
    })
    .addCase(fetchLocations.fulfilled, (state, action) => {
		const orgId= action.meta.arg.organizationId;
		const newEntry= newOrgEntry(orgId);
		const locationEntries = action.payload.map(l => {
           return newLocation(l);
        });
        const allEntries = action.payload.map(l => {
           return {id: l.id, organizationId: l.organizationId};
        });
        locationAdapter.addMany(state.all, allEntries);
        newEntry.locations= locationAdapter.addMany(newEntry.locations, locationEntries);
	    orgAdapter.setOne(state.orgMap, newEntry);
    })
    .addCase(fetchLocationsPublic.fulfilled, (state, action) => {
		const orgId= action.meta.arg.organizationId;
		const newEntry= newOrgEntry(orgId);
		const locationEntries = action.payload.map(l => {
           return newLocation(l);
        });
        const allEntries = action.payload.map(l => {
           return {id: l.id, organizationId: l.organizationId};
        });
        locationAdapter.addMany(state.all, allEntries);
        newEntry.locations= locationAdapter.addMany(newEntry.locations, locationEntries);
	    orgAdapter.setOne(state.orgMap, newEntry);
    })
    .addCase(fetchProgLocsByLocation.fulfilled, (state, action) => {
		const locationId = action.meta.arg.locationId;
	    const entry= state.all.entities[locationId];
	    if (entry){
			const orgEntry= state.orgMap.entities[entry.organizationId];
			if (orgEntry){
				const locEntry= orgEntry.locations.entities[locationId];
				if (locEntry) locationAdapter.setAll(locEntry.programs, action.payload);
			}    
		} 
    })
    .addCase(createLocation.fulfilled, (state, action) => {
		const orgId= action.payload.organizationId;
		const orgEntry= state.orgMap.entities[orgId];
		const location= newLocation(action.payload);
		locationAdapter.addOne(state.all, {id: location.id, organizationId: orgId});
		if (orgEntry){
			locationAdapter.addOne(orgEntry.locations, location);
		}else{
			const newEntry= newOrgEntry(orgId);
			newEntry.locations= locationAdapter.addOne(newEntry.locations, location);
			orgAdapter.addOne(state.orgMap, newEntry);
		}  
    })
    .addCase(updateLocation.fulfilled, (state, action) => {
		const orgId= action.payload.organizationId;
		const orgEntry= state.orgMap.entities[orgId];
		if (orgEntry){
			locationAdapter.upsertOne(orgEntry.locations, action.payload);
		}
    })
    .addCase(deleteLocation.fulfilled, (state, action) => {
	    const locationId = action.meta.arg.locationId;
	    const entry= state.all.entities[locationId];
	    if (entry){
			locationAdapter.removeOne(state.all, locationId);
			const orgEntry= state.orgMap.entities[entry.organizationId];
			if (orgEntry){
				locationAdapter.removeOne(orgEntry.locations, locationId);
			}    
		}     
    })
    .addCase(fetchLocationProfile.fulfilled, (state, action) => {
        locationAdapter.setOne(state.profiles, action.payload);
    })
  }
})

const { selectById: selectFromAllById } = locationAdapter.getSelectors(state => state.orgLocations.all);
const { selectById: selectOrgById } = orgAdapter.getSelectors(state => state.orgLocations.orgMap);
const { selectById: selectFromOrgById, selectAll: selectAllFromOrg} = locationAdapter.getSelectors(state => state.locations);
const { selectAll: selectProgramsFromLocation} = programAdapter.getSelectors(state => state.programs);
const { selectById: selectProfileById} = locationAdapter.getSelectors(state => state.orgLocations.profiles);


export const selectLocationById = (locationId) => state => {
	if (locationId) {
		const entry= selectFromAllById(state, locationId);
		if (entry){
			const orgEntry= selectOrgById(state, entry.organizationId);
			if (orgEntry){
				return selectFromOrgById(orgEntry, locationId);
			} 
		}
	}
	return null;
}

export const selectLocationProfileById = (locationId) => state => {
	if (locationId) return selectProfileById(state, locationId);
	else return null;
}

export const selectLocationsByOrgId = (orgId) => state => {
	if (orgId) {
		const orgEntry= selectOrgById(state, orgId);
		if (orgEntry){
			return selectAllFromOrg(orgEntry);
		} 
	}
	return emptyArray;
}

export const selectLocationProgramsById = (locationId) => state => {
	if (locationId) {
		const entry= selectFromAllById(state, locationId);
		if (entry){
			const orgEntry= selectOrgById(state, entry.organizationId);
			if (orgEntry){
				const locEntry= selectFromOrgById(orgEntry, locationId);
				if (locEntry) return selectProgramsFromLocation(locEntry);
			} 
		}
	}
	return emptyArray;
}

export const selectSelectedLocationsTab = () => state => {
	return state.orgLocations.selectedLocationsTab;
}

export const { setSelectedLocationsTab} = orgLocationsSlice.actions;

export default orgLocationsSlice.reducer;

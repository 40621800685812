import lazyLoad from 'app/shared-components/util/lazyLoad';
import FuseDialog from '@fuse/core/FuseDialog';
import { styled } from '@mui/material/styles';
import FuseMessage from '@fuse/core/FuseMessage';
import FuseSuspense from '@fuse/core/FuseSuspense';
import AppContext from 'app/AppContext';
import { memo, useContext, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { selectFuseCurrentLayoutConfig } from 'app/store/fuse/settingsSlice';
import { selectUser } from 'app/store/userSlice';
import FooterLayout1 from './components/FooterLayout1';
import LeftSideLayout1 from './components/LeftSideLayout1';
import NavbarWrapperLayout1 from './components/NavbarWrapperLayout1';
import RightSideLayout1 from './components/RightSideLayout1';
import ToolbarLayout1 from './components/ToolbarLayout1';
import ErrorBoundary from 'app/shared-components/error/ErrorBoundary';
import useVersionCheck from 'app/shared-components/util/useVersionCheck';
import UtilDialog from 'app/shared-components/layout/UtilDialog';
const InviteConnectionDialog = lazyLoad(() => import('app/shared-components/user/InviteConnectionDialog'));
const UserViewDialog = lazyLoad(() => import('app/shared-components/user/UserViewDialog'));
const SubscriptionExceededDialog = lazyLoad(() => import('app/shared-components/account/SubscriptionExceededDialog'));
const AIChatModal = lazyLoad(() => import('app/shared-components/aichat/AIChatModal'));
const DMChatModal = lazyLoad(() => import('app/shared-components/chat/DMChatModal'));
const VideoCallModal = lazyLoad(() => import('app/shared-components/chime/VideoCallModal'));


import { wrapUseRoutes } from "@sentry/react";


const Root = styled('div')(({ theme, config }) => ({
  ...(config.mode === 'boxed' && {
    clipPath: 'inset(0)',
    maxWidth: `${config.containerWidth}px`,
    margin: '0 auto',
    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
  }),
  ...(config.mode === 'container' && {
    '& .container': {
      maxWidth: `${config.containerWidth}px`,
      width: '100%',
      margin: '0 auto',
    },
  }),
}));

const useSentryRoutes = wrapUseRoutes(useRoutes);

function Layout1(props) {
  const config = useSelector(selectFuseCurrentLayoutConfig);
  const user = useSelector(selectUser);
  const isGuest= !user || !user.role || user.role.length === 0;
  const appContext = useContext(AppContext);
  const { routes } = appContext;
  useVersionCheck();

  return (
    <Root id="fuse-layout" config={config} className="w-full flex">
      {config.leftSidePanel.display && <LeftSideLayout1 />}

      <div className="flex flex-auto min-w-0">
        {config.navbar.display && config.navbar.position === 'left' &&  <ErrorBoundary><NavbarWrapperLayout1 /></ErrorBoundary>}

        <main id="fuse-main" className="flex flex-col flex-auto min-h-full min-w-0 relative z-10">
          {config.toolbar.display && (
            <ErrorBoundary><ToolbarLayout1 isGuest={isGuest} className={config.toolbar.style === 'fixed' && 'sticky top-0'} /></ErrorBoundary>
          )}

		     <ErrorBoundary>
            <div className="flex flex-col flex-auto min-h-0 relative z-10">
              
              <FuseDialog />
  
              <FuseSuspense>{useSentryRoutes(routes)}</FuseSuspense>
  
              {props.children}
            </div>
          </ErrorBoundary>

          {config.footer.display && (
            <ErrorBoundary><FooterLayout1 isGuest={isGuest} className={config.footer.style === 'fixed' && 'sticky bottom-0'} /></ErrorBoundary>
          )}
        </main>

        {config.navbar.display && config.navbar.position === 'right' && <ErrorBoundary><NavbarWrapperLayout1 isGuest={isGuest}/></ErrorBoundary>}
      </div>

      {config.rightSidePanel.display && <ErrorBoundary><RightSideLayout1 isGuest={isGuest}/></ErrorBoundary>}
      
      <ErrorBoundary>
	      <FuseMessage />
	      <UtilDialog />
	      
	      {!isGuest && 
	      <Suspense fallback={<div></div>}>
	        <DMChatModal />
  	      <SubscriptionExceededDialog />
  	      <UserViewDialog />
  	      <VideoCallModal />
  	      <InviteConnectionDialog />
  	      <AIChatModal />
	      </Suspense>
	      }
	      
	   </ErrorBoundary>
	  
    </Root>

  );
}

export default memo(Layout1);

import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import { apiGet, apiPut, apiDel, apiPost } from 'app/shared-components/util/restAPI';
import {  startLoading, stopLoading} from 'app/store/utilSlice';



export const fetchUserSettings = createAsyncThunk(
  "userSettings/fetchUserSettings",
  async ({bulk}, { dispatch, getState }) => {
  	  let data= bulk;
		  if (!data){
	  	  //Get the current user state
        const { user } = getState();
	      const apiName = 'CoreAPI2';
		    const path = '/x2/usersettings/' + user.id;
	      const options = {
				  headers: {},
				  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
			  };
			  data= await apiGet(apiName, path, options);
		  }
	    return data;
  }
)

export const updateUserSettings = createAsyncThunk(
  "userSettings/updateUserSettings",
  async ({settings}, { dispatch, getState }) => {
  	  //Get the current user state
      const { user } = getState();
      const apiName = 'CoreAPI2';
	    const path = '/x2/usersettings/' + user.id + '/updatesettings';
      const options = {
        body: settings,
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
		  const data= await apiPut(apiName, path, options);
	    return data;
  }
)

export const adminUpdateUserSettings = createAsyncThunk(
  "userSettings/updateUserSettings",
  async ({userId, settings}, { dispatch, getState }) => {
      const apiName = 'CoreAPI2';
	    const path = '/x2/usersettings/' + userId + '/updatesettings';
      const options = {
        body: settings,
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
		  const data= await apiPut(apiName, path, options);
	    return data;
  }
)

export const adminDisableUser = createAsyncThunk(
  "userSettings/adminDisableUser",
  async ({userId, value}, { dispatch, getState }) => {
      const apiName = 'CoreAPI2';
	    const path = '/x2/usersettings/' + userId + '/disable?value=' + value;
      const options = {
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
		  const data= await apiPut(apiName, path, options);
	    return data;
  }
)

export const adminMuteUser = createAsyncThunk(
  "userSettings/adminMuteUser",
  async ({userId, value}, { dispatch, getState }) => {
      const apiName = 'CoreAPI2';
	    const path = '/x2/usersettings/' + userId + '/mute?value=' + value;
      const options = {
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
		  const data= await apiPut(apiName, path, options);
	    return data;
  }
)

const userSettingsSlice = createSlice({
  name: 'userSettings',
  initialState: {
	  settings: {}, 
  },
  reducers: {

  },
  extraReducers: builder => {
    builder
    .addCase(fetchUserSettings.fulfilled, (state, action) => {
       state.settings= action.payload;
    })
    .addCase(updateUserSettings.fulfilled, (state, action) => {
       state.settings= action.payload;
    })
  },
});



export const selectUserSettings = ({ userSettings }) => userSettings.settings;
export default userSettingsSlice.reducer;

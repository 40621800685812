import { combineReducers } from '@reduxjs/toolkit';
import fuse from './fuse';
import i18n from './i18nSlice';
import user from './userSlice';
import util from './utilSlice';
import subscriptions from './subscriptionSlice';
import channels from './channelsSlice';
import post from './postSlice';
import channelInvites from './channelInvitesSlice';
import channelApplications from './channelApplicationsSlice';
import contacts from './contactsSlice';
import chimeMeeting from './chimeMeetingSlice';
import connections from './connectionsSlice';
import groups from './groupsSlice';
import home from './homeSlice';
import notifications from './notificationsSlice';
import fileItems from './fileItemsSlice';
import helpContent from './helpContentSlice';
import clients from './clientsSlice';
import clientsAuthorized from './clientsAuthorizedSlice';
import organizations from './organizationsSlice';
import organizationReferrals from './organizationReferralsSlice';
import programs from './programsSlice';
import orgLocations from './orgLocationsSlice';
import referrals from './referralsSlice';
import referralInterest from './referralInterestSlice';
import report from './reportSlice';
import externalMessages from './externalMessageSlice';
import dmChannels from './dmChannelSlice';
import support from './supportSlice';
import subscriptionPlan from './subscriptionPlanSlice';
import videoCall from './videoCallSlice';
import marketing from './marketing';
import warning from './warningSlice';
import aiChat from './aiChatSlice';
import userRecommendations from './userRecommendationSlice';
import userSettings from './userSettingsSlice';

const createReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    fuse,
    i18n,
    user,
    util,
    subscriptions,
    channels,
    channelInvites,
    channelApplications,
    chimeMeeting,
    contacts,
    post,
    connections,
    groups,
    home,
    notifications,
    fileItems,
    helpContent,
    clients,
    clientsAuthorized,
    organizations,
    organizationReferrals,
    programs,
    orgLocations,
    referrals,
    referralInterest,
    report,
    externalMessages,
    dmChannels,
    support,
    subscriptionPlan,
    videoCall,
    marketing,
    warning,
    aiChat,
    userRecommendations,
    userSettings,
    ...asyncReducers,
  });

  /*
	Reset the redux store when user logged out
  */
  if (action.type === 'user/userLoggedOut') {
    state = undefined;
    console.log("User logged out, resetting Redux store state.");
  }

  return combinedReducer(state, action);
};

export default createReducer;

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { FormHelperText } from '@mui/material';
import Select from '@mui/material/Select';
import { forwardRef } from 'react';
import { Box } from '@mui/system';


const SingleSelect = forwardRef((props, ref) => {
  const { value, onChange, className, id, label, items, errors, required, infoText, emptyChoice, useColors} = props;
  const useLabel = required && label ? label + " *" : label;
  const labelShrink= (emptyChoice && !value) ? true : undefined;
  
  const handleChange = (event) => {
    onChange(event);
  };
  
    return (
	    <FormControl fullWidth variant="outlined"  className={className}>
	      {label && <InputLabel shrink={labelShrink} id={"select-label-" + id}>{useLabel}</InputLabel> }
	      <Select
	        labelId={label ? "select-label-" + id : undefined}
	        label={useLabel}
	        variant="outlined"
	        required={required}
	        id={id}
	        value={value}
	        defaultValue=""
	        displayEmpty={Boolean(emptyChoice)}
	        onChange={handleChange}
	        error={!!errors[id]}
	        ref={ref}
	        classes={{ select: 'flex items-center space-x-12' }}
	      >
	      	{emptyChoice && 
	      		<MenuItem value="" key="emptyChoice" >
	      		{useColors &&
	            <Box
	              className="w-12 h-12 shrink-0 rounded-full"
	            />
	      		}
	            <span>{emptyChoice}</span>
	          </MenuItem>
	      	}
	        {items && items.map((item) => (
	          <MenuItem value={item.value} key={item.value} >
	          	{useColors &&
	            <Box
	              className="w-12 h-12 shrink-0 rounded-full"
	              sx={{ backgroundColor: item.bgColor }}
	            />
	          	}
	            <span>{item.name}</span>
	          </MenuItem>
	        ))}
	      </Select>
	     {errors && !!errors[id] && <FormHelperText>{errors[id]?.message}</FormHelperText> }
	     {(!errors || !errors[id]) && infoText && <FormHelperText>{infoText}</FormHelperText> }
	    </FormControl>
	  );
});


export default SingleSelect;



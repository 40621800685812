import { createAsyncThunk, createEntityAdapter, createSlice, createSelector } from '@reduxjs/toolkit';
import { stopLoading, startLoading} from 'app/store/utilSlice';
import { addUsersToAll } from 'app/store/contactsSlice';
import { getUser, fetchEmailInvites, removeEmailInvite } from 'app/store/contactFunctions';
import { fetchChannelUpdates } from './subscriptionSlice';
import { removeChannelInvite } from './channelInvitesSlice';
import { applyToChannel } from './channelApplicationsSlice';
import { addPost, deletePost, removePost, hidePost, updatePost, addPostInteraction, removePostInteraction, submitPost, fetchPostsByChannelId, selectAllPosts } from 'app/store/postSlice'
import sortComparer from 'app/shared-components/util/lastMessageSortComparer';
import queryString from 'app/shared-components/util/queryString';
import { apiGet, apiPut, apiDel, apiPost } from 'app/shared-components/util/restAPI';

export const ROLE_OWNER = "owner";
export const ROLE_ADMIN = "admin";
export const ROLE_MEMBER = "member";
export const ROLE_READER = "reader";

export const channelsAdapter = createEntityAdapter({
	sortComparer: sortComparer
});
const membersAdapter = createEntityAdapter();
const postsAdapter = createEntityAdapter({
	sortComparer: (a, b) => b.createdAt - a.createdAt
});
const reversePostsAdapter = createEntityAdapter({
	sortComparer: (a, b) => a.createdAt - b.createdAt
});
const invitesAdapter = createEntityAdapter({
	selectId: (invite) => invite.userId,
});
const emailInvitesAdapter = createEntityAdapter({});
const applicationsAdapter = createEntityAdapter({
	selectId: (application) => application.userId,
});

const emptyArray= [];

export const fetchChannels = createAsyncThunk(
  "channels/fetchChannels",
  async (params) => {
      const apiName = 'CoreAPI';
	  const path = '/channel/bulk';
      const options = {
		  body: params.idList,
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };

	 let data= await apiPost(apiName, path, options);
	 return data;
  }
);

export const fetchChannel = createAsyncThunk(
  "channels/fetchChannel",
  async ({channelId, reverseSort, bulk, consistent}) => {
	  if (bulk) return bulk; //pre-fetched via bulk
	  
      const apiName = 'CoreAPI';
	    const path = '/channel/' + channelId;
	    const query = consistent ? '?consistent=true' : '';
      const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };

	 let data= await apiGet(apiName, path + query, options);
	 return data;
  }
);

export const fetchChannelPublic = createAsyncThunk(
  "channels/fetchChannelPublic",
  async ({channelId, reverseSort}) => {
      const apiName = 'PublicAPI';
	  const path = '/channel/' + channelId + '/public';
      const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };

	 let data= await apiGet(apiName, path, options);
	 return data;
  }
);



export const leaveChannel = createAsyncThunk(
  "channels/leaveChannel",
  async (id, { dispatch, getState }) => {
	  const { user } = getState();
      const apiName = 'CoreAPI';
	  const path = '/channel/' + id + '/channelmember/' + user.id;
      const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };
	  
	  //delete channel member
	  const data= await apiDel(apiName, path, options);
      return user.id;
  }
);

export const removeUserFromChannel = createAsyncThunk(
  "channels/removeUserFromChannel",
  async ({userId, channelId}, { dispatch, getState }) => {
      const apiName = 'CoreAPI';
	    const path = '/channel/' + channelId + '/channelmember/' + userId;
      const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };
	  
	  //delete channel member
	  const data= await apiDel(apiName, path, options);
    return userId;
  }
);

export const joinChannel = createAsyncThunk(
  "channels/joinChannel",
  async (params, { dispatch, getState }) => {
	  const { user } = getState();
	  const { channelId, role} = params;
      const apiName = 'CoreAPI';
	  const path = '/channel/' + channelId + '/channelmember';
      const options = {
		  body: {userId: user.id, memberRole: role},
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };
	  
	  //Store channel member data
	    const data= await apiPut(apiName, path, options);
      return user.raw;
  }
);

export const adminUpdateRole = createAsyncThunk(
  "channels/adminUpdateRole",
  async (params, { dispatch, getState }) => {
	  const { channelId, userId, role, oldRole} = params;
      const apiName = 'CoreAPI';
	  const path = '/channel/' + channelId + '/channelmember/' + userId;
      const options = {
		  body: {memberRole: role},
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };
	  
	  //Store channel member data
	  const data= await apiPut(apiName, path, options);
    return data;
  }
);

export const approveChannelApplication = createAsyncThunk(
  "channels/approveChannelApplication",
  async (params, { dispatch, getState }) => {
	  const { channelId, userId, role} = params;
      const apiName = 'CoreAPI';
	  const path = '/channel/' + channelId + '/channelmember';
      const options = {
		  body: {userId: userId, memberRole: role},
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };
	  
	  //Store channel member data
	  const data= await apiPut(apiName, path, options);
	  dispatch(fetchMemberByUserId({channelId: channelId, userId: userId, role: role}));
      return data;
  }
);

export const declineChannelApplication = createAsyncThunk('channelApplications/declineChannelApplication', 
  async (params, { getState, dispatch }) => {
	  const { channelId, userId} = params;
	  const url= '/user/' + userId + '/channelapplication/' + channelId;
	  const apiName = 'CoreAPI';
	  const path = url;
	  const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };
	
	  let data= await apiDel(apiName, path, options);
	  return data;
});

export const updateSizeLimit = createAsyncThunk(
  "channels/updateSizeLimit",
  async ({ channelId }, { dispatch, getState }) => {
      const apiName = 'CoreAPI';
	    const path = '/channel/' + channelId + '/updatemaxmembers';
      const options = {
		  body: {},
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };
	  
	  //Store channel member data
	  const data= await apiPut(apiName, path, options);
    return data;
  }
);

export const fetchMemberByUserId = createAsyncThunk(
  "channels/fetchMemberByUserId",
  async (params, { dispatch, getState }) => {
	 const {channelId, userId, role} = params;

	 let data= await getUser(userId);
     return data;
  }
)

export const inviteToChannel = createAsyncThunk(
  "channels/inviteToChannel",
  async (params, { dispatch, getState, rejectWithValue }) => {
	  const { channelId, userId, message, role} = params;
	  const { user }= getState();
      const apiName = 'CoreAPI';
	    const path = '/channel/' + channelId + '/channelinvite';
      const options = {
		  body: {userId: userId, fromUserId: user.id, memberRole: role, message: message},
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };
	  
	  try {
	 	 const data= await apiPut(apiName, path, options);
      	 return data;
	  } catch (err) {
	      if (!err.response) {
	        throw err;
	      }
	      return rejectWithValue(err.response.data)
	  }
	  
  }
);


export const fetchMembersByChannelId = createAsyncThunk(
  "channels/fetchMembersByChannelId",
  async (params, { dispatch, getState }) => {
	  const {channelId, role, bulk, startFromId} = params;
	  let data= bulk;
	  if (!data){
	    if (startFromId){
  	    const channelEntry = getState().channels.entities[channelId];
    		let membersEntry= channelEntry.members;
    		if (role) membersEntry= channelEntry[role];
    		if (membersEntry.fetchedAll) return emptyArray;
	    }
    		
		  const apiName = 'CoreAPI';
		  const queryRole= role ? 'role=EQ:' + role :  undefined;
      const queryStart= startFromId ? "startFromId=" + startFromId : undefined;
		  const path = '/channel/' + channelId + '/channelmember/users' + queryString(queryRole, queryStart);
	      const options = {
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
		  data= await apiGet(apiName, path, options); 
	  }
      
	   if (data.length > 0) dispatch(addUsersToAll(data));
     return data;
  }
)


export const fetchInvitesByChannelId = createAsyncThunk(
  "channels/fetchInvitesByChannelId",
  async (params, { dispatch, getState }) => {
	    const {channelId} = params;
      const apiName = 'CoreAPI';
	    const path = '/channel/' + channelId + '/channelinvite';
      const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };

	   let data= await apiGet(apiName, path, options);
     return data;
  }
)

export const fetchEmailInvitesByChannelId= createAsyncThunk('channels/fetchEmailInvitesByChannelId',
 async (params, { dispatch, getState }) => {
	 try{
		    const { channelId }= params;
			  dispatch(startLoading());
			  //Need qualified channel key since email invite owner can also be a user
		  	let data= await fetchEmailInvites("Channel:" + channelId);
		    return data;
	}finally{
		dispatch(stopLoading());
	}
});

export const removeChannelEmailInvitation= createAsyncThunk('channels/removeChannelEmailInvitation',
 	async (params, { dispatch, getState }) => {
 	  const { id, channelId }= params;
    console.log("Inside removeChannelEmailInvitation: " + id);
    await removeEmailInvite(id);
    return id;
});

export const fetchApplicationsByChannelId = createAsyncThunk(
  "channels/fetchApplicationsByChannelId",
  async (params, { dispatch, getState }) => {
	  const {channelId} = params;
      const apiName = 'CoreAPI';
	  const path = '/channel/' + channelId + '/channelapplication';
      const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };

	 let data= await apiGet(apiName, path, options);
     return data;
  }
)

export const setChannelRead = createAsyncThunk('channels/setChannelRead', 
  async (params, { getState, dispatch }) => {
	  const { user } = getState();
	  const { channelId } = params;
	  let data= await saveChannelUser(channelId, user.id, true);
	  return channelId;
});

export const saveChannelUser = async (channelId, userId, updateReadTimestamp) => {
	  const url= '/channel/' + channelId + '/channeluser/' + userId;
	  const apiName = 'CoreAPI';
	  const path = url;
	  const options = {
		  body: { updateReadTimestamp: updateReadTimestamp },
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };
	
	  let data= await apiPut(apiName, path, options);
	  return data;
};

export const refreshChannelPosts = createAsyncThunk(
  "channels/refreshChannelPosts",
  async (params, { dispatch, getState }) => {
      const { channelId }= params;
      const channelEntry = getState().channels.entities[channelId];
      if (channelEntry) {
		  const lastFetched= channelEntry.postsFetched.timestamp;
		  const lastUpdated= channelEntry.lastUpdate;
		  const updatesFrom= lastUpdated ? lastUpdated : lastFetched;

		  if (updatesFrom){
			  dispatch(fetchChannelUpdates({channelId: channelId, fromTimestamp: updatesFrom}));
		  }else{
			  dispatch(fetchPostsByChannelId({channelId: channelId}));
		  }
	  }
  }
);

export const fetchOlderChannelPosts = createAsyncThunk(
  "channels/fetchOlderChannelPosts",
  async (params, { dispatch, getState }) => {
      const { channelId }= params;
      const channelEntry = getState().channels.entities[channelId];
      if (channelEntry) {
  		  const {oldest, fetchedAll }= channelEntry.postsFetched;
  		  if (fetchedAll) return;
  		  const updatesFrom= oldest?.createdAt;
  
  		  if (updatesFrom){
  			  const resp= await dispatch(fetchPostsByChannelId({channelId: channelId, before: updatesFrom}));
  			  if (!resp.error && resp.payload.length === 0){
  				  return {fetchedAll: true};
  			  }
  		  }
  		  return;
  	  }
  }
);

export const fetchNewerChannelPosts = createAsyncThunk(
  "channels/fetchNewerChannelPosts",
  async (params, { dispatch, getState }) => {
      const { channelId }= params;
      const channelEntry = getState().channels.entities[channelId];
      if (channelEntry) {
		  const {newest }= channelEntry.postsFetched;
		  const updatesFrom= newest?.createdAt;
			dispatch(fetchPostsByChannelId({channelId: channelId, after: updatesFrom}));
		  return;
	  }
  }
);

export const newChannel= (data) => {
	const {id, name, parentId, rootId, accessSettings, reverseSort, parentType, maxMembers,
	       callerPermissions, channelType, unread, lastMessageTimestamp, memberCount,
	       clientId, entity, parentPostId }= data;
	const isComplex= (channelType === 'group' || channelType === 'team' || channelType === 'organization');
	return {id: id, name: entity ? entity.name : name, parentId: parentId, rootId: rootId,  parentType: parentType,
	        type: channelType, reverseSort: reverseSort, clientId: clientId, memberCount: memberCount, maxMembers: maxMembers,
	        entity: entity, entityId: entity?.id, parentPostId: parentPostId,
	        accessSettings: accessSettings, callerPermissions: callerPermissions, unread: unread,
	        postsFetched: {timestamp: null, newest: null, oldest: null, fetchedAll: false},
	        needsRefresh: true, lastUpdate: null, lastMessageTimestamp: lastMessageTimestamp,
	        lastSentTimestamp: null,
	        invites: isComplex ? invitesAdapter.getInitialState({status: "idle"}) : null,
	        emailInvites: isComplex ? emailInvitesAdapter.getInitialState({status: "idle"}) : null,
	        applications: channelType === 'group' ? applicationsAdapter.getInitialState({status: "idle"}) : null,
	        posts: postsAdapter.getInitialState({status: "idle"}), 
	        members: membersAdapter.getInitialState({status: "idle", lastId: null, fetchedAll: false}),
	        reader: isComplex ? membersAdapter.getInitialState({status: "idle", lastId: null, fetchedAll: false}) : null,
		    guest: isComplex ? membersAdapter.getInitialState({status: "idle", lastId: null, fetchedAll: false}) : null,
	        member: isComplex ? membersAdapter.getInitialState({status: "idle", lastId: null, fetchedAll: false}) : null,
	        owner: isComplex ? membersAdapter.getInitialState({status: "idle", lastId: null, fetchedAll: false}) : null,
	        admin: isComplex ? membersAdapter.getInitialState({status: "idle", lastId: null, fetchedAll: false}) : null}
}

const addPostToChannel= (post, channelEntry, state) => {
  if (channelEntry) {
    	//Only add the post to channel if it has already fetched initial posts
    	if (channelEntry.postsFetched.timestamp){
    		if (channelEntry.reverseSort) reversePostsAdapter.addOne(channelEntry.posts, convertPost(post));
    	  else postsAdapter.addOne(channelEntry.posts, convertPost(post));
      }
      
      //Update last message timestamp - use upsertOne so channel can be sorted properly
      const lastMessageTimestamp= channelEntry.lastMessageTimestamp ? Math.max(channelEntry.lastMessageTimestamp, post.createdAt) : post.createdAt;
      channelsAdapter.upsertOne(state, {id: channelEntry.id, lastMessageTimestamp: lastMessageTimestamp});
  }
}

const convertPost= (post) => {
  return {id: post.id, createdAt: post.createdAt }
}

const convertPosts= (posts) => {
  return posts.map((post) => convertPost(post));
}

const channelsSlice = createSlice({
  name: "channels",
  initialState: channelsAdapter.getInitialState({status: "idle", viewingChannelIds: [], manageUser: {}, invite: {}, application: {}}),
  reducers: {
	addChannel:  (state, action) => {
		const {channel, refresh}= action.payload;
		const existing=  selectChannelByIdRaw(state, channel.id);
		if (existing && !refresh) return;
        const channelEntry= newChannel(channel);
        channelsAdapter.setOne(state, channelEntry);
	},
	removeChannel:  (state, action) => {
        channelsAdapter.removeOne(state, action.payload);
	},
	addChannels:  (state, action) => {
		const channelEntries = action.payload.map(channel => {
           return newChannel(channel);
        });
        channelsAdapter.setMany(state, channelEntries);
	},
	setChannelRefresh: (state, action) => {
        const channelEntry = state.entities[action.payload];
        if (channelEntry) channelEntry.needsRefresh= true;
	},
	setChannelUnread: (state, action) => {
        const channelEntry = state.entities[action.payload];
        if (channelEntry) channelEntry.unread= true;
	},
    addViewingChannelId: (state, action) => {
      state.viewingChannelIds.push(action.payload);
    },
    removeViewingChannelId: (state, action) => {
      state.viewingChannelIds= state.viewingChannelIds.filter((id) => id != action.payload);
    },
    setChannelInvite: (state, action) => {
      state.invite= action.payload;
    },
    setChannelManageUser: (state, action) => {
      state.manageUser= action.payload;
    },
    setChannelApplication: (state, action) => {
      state.application= action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchChannels.fulfilled, (state, action) => {
	  const reverseSort= action.meta.arg.reverseSort;
      const channelEntries = action.payload.map(channel => {
        return newChannel({...channel, reverseSort: reverseSort});
      });
      channelsAdapter.setMany(state, channelEntries);
    })
/*    .addCase(fetchChannel.pending, (state, action) => {
      const channelId = action.meta.arg.channelId;
	  let channelEntry = newChannel({id: channelId});
  	  channelsAdapter.addOne(state, channelEntry);
    })*/
    .addCase(fetchChannel.fulfilled, (state, action) => {
		const reverseSort= action.meta.arg.reverseSort;
		const parentType= action.meta.arg.parentType;
	    const channelEntry= newChannel({...action.payload, reverseSort: reverseSort, parentType: parentType});
        channelsAdapter.setOne(state, channelEntry);
    })
    .addCase(fetchChannelPublic.fulfilled, (state, action) => {
		const reverseSort= action.meta.arg.reverseSort;
	    const channelEntry= newChannel({...action.payload, reverseSort: reverseSort});
        channelsAdapter.setOne(state, channelEntry);
    })
    .addCase(leaveChannel.fulfilled, (state, action) => {
      const channelId = action.meta.arg;
      const userId= action.payload;
      const channelEntry = state.entities[channelId];
      if (channelEntry) {
		  membersAdapter.removeOne(channelEntry.members, userId);
		  membersAdapter.removeOne(channelEntry.member, userId);
		  membersAdapter.removeOne(channelEntry.admin, userId);
		  membersAdapter.removeOne(channelEntry.owner, userId);
		  membersAdapter.removeOne(channelEntry.reader, userId);
      }
    })
    .addCase(joinChannel.fulfilled, (state, action) => {
      const channelId = action.meta.arg.channelId;
      const role = action.meta.arg.role;
      const channelEntry = state.entities[channelId];
      if (channelEntry) {
		    membersAdapter.addOne(channelEntry.members, action.payload);
  		  if (channelEntry[role]) membersAdapter.addOne(channelEntry[role], action.payload);
      }
    })
    .addCase(updateSizeLimit.fulfilled, (state, action) => {
      const channelId = action.meta.arg.channelId;
      const channelEntry = state.entities[channelId];
      if (channelEntry) {
		    channelEntry.maxMembers= action.payload.maxMembers;
      }
    })
    .addCase(adminUpdateRole.fulfilled, (state, action) => {
      const channelId = action.meta.arg.channelId;
      const userId = action.meta.arg.userId;
      const role = action.meta.arg.role;
      const oldRole = action.meta.arg.oldRole;
      const channelEntry = state.entities[channelId];
      if (channelEntry) {
        const userEntry= channelEntry[oldRole].entities[userId];
  		  membersAdapter.addOne(channelEntry[role], userEntry);
  		  membersAdapter.removeOne(channelEntry[oldRole], userId);
      }
    })
    .addCase(removeUserFromChannel.fulfilled, (state, action) => {
      const channelId = action.meta.arg.channelId;
      const userId= action.meta.arg.userId;
      const channelEntry = state.entities[channelId];
      if (channelEntry) {
  		  membersAdapter.removeOne(channelEntry.members, userId);
  		  membersAdapter.removeOne(channelEntry.member, userId);
  		  membersAdapter.removeOne(channelEntry.admin, userId);
  		  membersAdapter.removeOne(channelEntry.owner, userId);
  		  membersAdapter.removeOne(channelEntry.reader, userId);
      }
    })
    .addCase(approveChannelApplication.fulfilled, (state, action) => {
      const channelId = action.meta.arg.channelId;
      const channelEntry = state.entities[channelId];
      if (channelEntry) applicationsAdapter.removeOne(channelEntry.applications, action.meta.arg.userId);
    })
    .addCase(declineChannelApplication.fulfilled, (state, action) => {
      const channelId = action.meta.arg.channelId;
      const channelEntry = state.entities[channelId];
      if (channelEntry) applicationsAdapter.removeOne(channelEntry.applications, action.meta.arg.userId);
	  })
    .addCase(fetchMemberByUserId.fulfilled, (state, action) => {
      const channelId = action.meta.arg.channelId;
      const role = action.meta.arg.role;
      const channelEntry = state.entities[channelId];
      if (channelEntry) {
		    membersAdapter.addOne(channelEntry.members, action.payload);
		    membersAdapter.addOne(channelEntry[role], action.payload);
      }
    })
    .addCase(inviteToChannel.fulfilled, (state, action) => {
		    const channelId = action.meta.arg.channelId;
        const channelEntry = state.entities[channelId];  
        const invite= action.payload;
        if (channelEntry) {
		  	  invitesAdapter.addOne(channelEntry.invites, invite);
		    }
    })
    .addCase(removeChannelInvite.fulfilled, (state, action) => {
  		const {channelId, userId } = action.meta.arg;
  		if (userId){
  		    const channelEntry = state.entities[channelId];  
  	      if (channelEntry) {
  				  invitesAdapter.removeOne(channelEntry.invites, userId);
  			  }	
  		}	        
    })
    .addCase(removeChannelEmailInvitation.fulfilled, (state, action) => {
  		const {id, channelId } = action.meta.arg;
  		const channelEntry = state.entities[channelId];  
  	  if (channelEntry) {
  				emailInvitesAdapter.removeOne(channelEntry.emailInvites, id);
  		 }	
    })
    .addCase(fetchInvitesByChannelId.fulfilled, (state, action) => {
      const channelId = action.meta.arg.channelId;
      const channelEntry = state.entities[channelId];
      if (channelEntry) {
		  let invitesEntry= channelEntry.invites;
		  invitesEntry.status= "succeeded";
		  invitesAdapter.setAll(invitesEntry, action.payload);  
      }
    })
    .addCase(fetchEmailInvitesByChannelId.fulfilled, (state, action) => {
      const channelId = action.meta.arg.channelId;
      const channelEntry = state.entities[channelId];
      if (channelEntry) {
  		  let invitesEntry= channelEntry.emailInvites;
  		  invitesEntry.status= "succeeded";
  		  emailInvitesAdapter.setAll(invitesEntry, action.payload);  
      }
    })
    .addCase(fetchApplicationsByChannelId.fulfilled, (state, action) => {
      const channelId = action.meta.arg.channelId;
      const channelEntry = state.entities[channelId];
      if (channelEntry) {
		  let appsEntry= channelEntry.applications;
		  appsEntry.status= "succeeded";
		  applicationsAdapter.setAll(appsEntry, action.payload);  
      }
    })
    .addCase(fetchMembersByChannelId.pending, (state, action) => {
      if (action.meta.arg.startFromId) return;
      const channelId = action.meta.arg.channelId;
      const role = action.meta.arg.role;
      const channelEntry = state.entities[channelId];
      if (channelEntry) {
  		  let membersEntry= channelEntry.members;
  		  if (role){
  			   membersEntry= channelEntry[role];
  		  }
  		  membersEntry.status= "loading";
      }
    })
    .addCase(fetchMembersByChannelId.rejected, (state, action) => {
      if (action.meta.arg.startFromId) return;
      const channelId = action.meta.arg.channelId;
      const role = action.meta.arg.role;
      const channelEntry = state.entities[channelId];
      if (channelEntry) {
  		  let membersEntry= channelEntry.members;
  		  if (role){
  			   membersEntry= channelEntry[role];
  		  }
  		  membersEntry.status= "failed";
      }
    })
    .addCase(fetchMembersByChannelId.fulfilled, (state, action) => {
      const channelId = action.meta.arg.channelId;
      const role = action.meta.arg.role;
      const startFromId = action.meta.arg.startFromId;
      const channelEntry = state.entities[channelId];
      if (channelEntry) {
  		  let membersEntry= channelEntry.members;
  		  if (role){
  			   membersEntry= channelEntry[role];
  		  }
  		  membersEntry.status= "succeeded";
  		  if (startFromId){
  			  if (action.payload.length === 0) {
  			    if (!membersEntry.fetchedAll) membersEntry.fetchedAll= true;
  			  }
  			  else {
  			    membersAdapter.addMany(membersEntry, action.payload); 
  			  }
  		  }
  		  else membersAdapter.setAll(membersEntry, action.payload);  
  		  if (action.payload.length > 0) membersEntry.lastId= action.payload[action.payload.length - 1].id;
      }
    })
    .addCase(fetchOlderChannelPosts.fulfilled, (state, action) => {
      const channelId = action.meta.arg.channelId;
      const channelEntry = state.entities[channelId];
      if (channelEntry) {
		     if (action.payload?.fetchedAll) channelEntry.postsFetched.fetchedAll= true;
      }
    })
    .addCase(fetchPostsByChannelId.fulfilled, (state, action) => {
      const channelId = action.meta.arg.channelId;
      const channelEntry = state.entities[channelId];
      if (channelEntry) {
      		channelEntry.posts.status= "succeeded";
      		
      		//Don't update fetched timestamp if fetching older posts
      		if (!action.meta.arg.before){
      	        channelEntry.postsFetched.timestamp= Date.now();
      		}
    	    
    	    if (action.payload.length > 0){
    			if (channelEntry.reverseSort) reversePostsAdapter.setMany(channelEntry.posts, convertPosts(action.payload));
          else postsAdapter.setMany(channelEntry.posts, convertPosts(action.payload));
          const newest= action.payload[0];
          const oldest= action.payload[action.payload.length -1];
          if (!channelEntry.postsFetched.newest || channelEntry.postsFetched.newest.createdAt < newest.createdAt)
            		channelEntry.postsFetched.newest= newest;
          if (!channelEntry.postsFetched.oldest || channelEntry.postsFetched.oldest.createdAt > oldest.createdAt)
            		channelEntry.postsFetched.oldest= oldest;
    		}
      }
    })
    .addCase(fetchPostsByChannelId.pending, (state, action) => {
      const channelId = action.meta.arg.channelId;
      const channelEntry = state.entities[channelId];
      if (channelEntry) {
        channelEntry.posts.status= "loading";
      }
    })
    .addCase(fetchPostsByChannelId.rejected, (state, action) => {
      const channelId = action.meta.arg;
      const channelEntry = state.entities[channelId];
      if (channelEntry) {
        channelEntry.posts.status= "failed";
        channelEntry.posts.error = action.error.message;
        channelEntry.needsRefresh= true;
      }
    })
    .addCase(refreshChannelPosts.fulfilled, (state, action) => {
      const channelId = action.meta.arg.channelId;
      const channelEntry = state.entities[channelId];
      if (channelEntry) {
        channelEntry.needsRefresh= false;
      }
    })
    .addCase(submitPost.fulfilled, (state, action) => {
      const channelId = action.meta.arg.channelId;
      const channelEntry = state.entities[channelId];
      if (channelEntry) {
        addPostToChannel(action.payload, channelEntry, state);
        channelEntry.lastSentTimestamp= action.payload.createdAt;
      }
    })
    .addCase(addPost, (state, action) => {      
      const {post, subscriptionChannelId, publishedAt}= action.payload;
      const channelId = post.channelId;
      const channelEntry = state.entities[channelId];
      if(channelEntry){
        //Add post to channel and update lastUpdate timestamp
        addPostToChannel(post, channelEntry, state);
        
        //If the channel has alread fetched initial posts, update the last update time for subscription
  	   if (channelEntry.postsFetched?.timestamp){
    		 const subChannelEntry = state.entities[subscriptionChannelId];
    	      if (subChannelEntry) {
    			if (!subChannelEntry.lastUpdate || subChannelEntry.lastUpdate < publishedAt) subChannelEntry.lastUpdate= publishedAt;
    	      }	  
    	  };
      }
    })
    .addCase(removePost, (state, action) => {      
      const {post, subscriptionChannelId, publishedAt}= action.payload;
      const channelId = post.channelId;
      const channelEntry = state.entities[channelId];
      if (channelEntry) {
		if (channelEntry.reverseSort) reversePostsAdapter.removeOne(channelEntry.posts, post.id);
        else postsAdapter.removeOne(channelEntry.posts, post.id);
      }
      const subChannelEntry = state.entities[subscriptionChannelId];
      if (subChannelEntry) {
		if (!subChannelEntry.lastUpdate || subChannelEntry.lastUpdate < publishedAt) subChannelEntry.lastUpdate= publishedAt;
      }
    })
    .addCase(updatePost, (state, action) => {      
      const {subscriptionChannelId, publishedAt}= action.payload;
      const subChannelEntry = state.entities[subscriptionChannelId];
      if (subChannelEntry) {
		if (!subChannelEntry.lastUpdate || subChannelEntry.lastUpdate < publishedAt) subChannelEntry.lastUpdate= publishedAt;
      }
    })
    .addCase(deletePost.fulfilled, (state, action) => {
      const channelId = action.meta.arg.channelId;
      const postId = action.meta.arg.postId;
      const channelEntry = state.entities[channelId];
      if (channelEntry) {
		    if (channelEntry.reverseSort) reversePostsAdapter.removeOne(channelEntry.posts, postId);
        else postsAdapter.removeOne(channelEntry.posts, postId);
      }
    })
    .addCase(hidePost.fulfilled, (state, action) => {
      const channelId = action.meta.arg.channelId;
      const postId = action.meta.arg.postId;
      const channelEntry = state.entities[channelId];
      if (channelEntry) {
	    	if (channelEntry.reverseSort) reversePostsAdapter.removeOne(channelEntry.posts, postId);
        else postsAdapter.removeOne(channelEntry.posts, postId);
      }
    })
    .addCase(addPostInteraction, (state, action) => {      
      const {subscriptionChannelId, publishedAt}= action.payload;
      const subChannelEntry = state.entities[subscriptionChannelId];
      if (subChannelEntry) {
		if (!subChannelEntry.lastUpdate || subChannelEntry.lastUpdate < publishedAt) subChannelEntry.lastUpdate= publishedAt;
      }
    })
    .addCase(removePostInteraction, (state, action) => {      
      const {subscriptionChannelId, publishedAt}= action.payload;
      const subChannelEntry = state.entities[subscriptionChannelId];
      if (subChannelEntry) {
		if (!subChannelEntry.lastUpdate || subChannelEntry.lastUpdate < publishedAt) subChannelEntry.lastUpdate= publishedAt;
      }
    })
    .addCase(setChannelRead.fulfilled, (state, action) => {
      const channelId = action.payload;
      console.log("SetChannelRead for " + channelId);
      const channelEntry = state.entities[channelId];
      if (channelEntry) {
        channelEntry.unread= false;
      }
    })
    .addCase(applyToChannel.fulfilled, (state, action) => {
      const channelId = action.meta.arg.channelId;
      const channelEntry = state.entities[channelId];
      if (channelEntry){
		  applicationsAdapter.addOne(channelEntry.applications, action.payload);
	    }
    })
  }
})


const { selectById: selectChannelByIdRaw } = channelsAdapter.getSelectors();

export const {
  selectAll: selectAllChannels, selectById: selectChannelById
} = channelsAdapter.getSelectors(state => state.channels);

export const {
  selectAll: selectChannelPosts, selectById: selectPostById
} = postsAdapter.getSelectors(state => state.posts);

const {
  selectAll: selectChannelMembersInternal,
} = membersAdapter.getSelectors();

export const {
  selectAll: selectChannelAllMembers, selectById: selectMemberById
} = membersAdapter.getSelectors(state => state.members);

export const {
  selectAll: selectChannelMembers,
} = membersAdapter.getSelectors(state => state.member);

export const {
  selectAll: selectChannelReaders,
} = membersAdapter.getSelectors(state => state.reader);

export const {
  selectAll: selectChannelAdmins,
} = membersAdapter.getSelectors(state => state.admin);

export const {
  selectAll: selectChannelOwners,
} = membersAdapter.getSelectors(state => state.owner);

export const {
  selectAll: selectChannelInvites, selectById: selectInviteById
} = invitesAdapter.getSelectors(state => state.invites);

export const {
  selectAll: selectChannelEmailInvites, selectById: selectEmailInviteById
} = emailInvitesAdapter.getSelectors(state => state.emailInvites);

export const {
  selectAll: selectChannelApplications, selectById: selectApplicationById
} = applicationsAdapter.getSelectors(state => state.applications);


export const selectChannel = (channelId) => state => {
	if (channelId) return selectChannelById(state, channelId);
	return null;
}

export const selectInvitesByChannelId = (channelId) => state => {
  if (channelId){
		const channel= selectChannelById(state, channelId);
		if (channel) return selectChannelInvites(channel);
	}
	return emptyArray;
}

export const selectEmailInvitesByChannelId = (channelId) => state => {
  if (channelId){
		const channel= selectChannelById(state, channelId);
		if (channel) return selectChannelEmailInvites(channel);
	}
	return emptyArray;
}


export const selectApplicationsByChannelId = (channelId) => state => {
    if (channelId){
		const channel= selectChannelById(state, channelId);
		if (channel){
			const apps= selectChannelApplications(channel);
		    return apps;
		}
	}
	return emptyArray;
}
	
export const selectPostsByChannelId = (channelId) => state => {
    if (channelId){
		const channel= selectChannelById(state, channelId);
		if (channel){
			const posts= selectChannelPosts(channel);
		  return posts;
		}
	}
	return emptyArray;
}

const internalSelectPostsByChannelId = (state, channelId) => {
    if (channelId){
		  const channel= selectChannelById(state, channelId);
		  if (channel){
			  const posts= selectChannelPosts(channel);
		    return posts;
		  }
	}
	return emptyArray;
}


const internalSelectFullPostsByChannelId = createSelector([internalSelectPostsByChannelId, selectAllPosts], (posts, entities) => {
   return posts.map((post) => entities[post.id]);
})



export const selectFullPostsByChannelId = (channelId) => state => {
		return internalSelectFullPostsByChannelId(state, channelId);
}


export const selectAllMembersByChannelId = (channelId) => state => {
    if (channelId){
  		const channel= selectChannelById(state, channelId);
  		if (channel){
  			  const members= selectChannelAllMembers(channel);
  		    return members;
  		}
  	}
	return emptyArray;
}

export const selectReadersByChannelId = (channelId) => state => {
  if (channelId){
    const channel= selectChannelById(state, channelId);
    if (channel){
        const members= selectChannelReaders(channel);
        return members;
    }
  }
 return emptyArray;
}

export const selectMembersByChannelId = (channelId) => state => {
    if (channelId){
  		const channel= selectChannelById(state, channelId);
  		if (channel){
  			  const members= selectChannelMembers(channel);
  		    return members;
  		}
  	}
	return emptyArray;
}

export const selectAdminsByChannelId = (channelId) => state => {
    if (channelId){
		const channel= selectChannelById(state, channelId);
		if (channel){
			  const members= selectChannelAdmins(channel);
		    return members;
		}
	}
	return emptyArray;
}

export const selectOwnersByChannelId = (channelId) => state => {
    if (channelId){
		const channel= selectChannelById(state, channelId);
		if (channel){
			  const members= selectChannelOwners(channel);
		    return members;
		}
	}
	return emptyArray;
}

export const selectMemberByIds = (channelId, userId) => state => {
    if (channelId){
		const channel= selectChannelById(state, channelId);
		if (channel){
			const member= selectMemberById(channel, userId);
		    return member;
		}
	}
	return null;
}

export const selectPostsStatusByChannelId = (channelId) => state => {
	if (channelId){
		const channel= selectChannelById(state, channelId);
		if (channel) return channel.posts.status;
  }
	return null;
}

export const selectMembersByChannelIdAndRole = (channelId, role) => state => {
	if (channelId){
		const channel= selectChannelById(state, channelId);
		if (channel) {
		    let membersEntry= channel.members;
  		  if (role) membersEntry= channel[role];
		    return selectChannelMembersInternal(membersEntry);
		}
  }
	return emptyArray;
}

export const selectMembersStatusByChannelId = (channelId, role) => state => {
	if (channelId){
		const channel= selectChannelById(state, channelId);
		if (channel) {
		    let membersEntry= channel.members;
  		  if (role) membersEntry= channel[role];
		    return membersEntry.status;
		}
  }
	return null;
}

export const selectFetchedAllMembersByChannelId = (channelId, role) => state => {
	if (channelId){
		const channel= selectChannelById(state, channelId);
		if (channel) {
		    let membersEntry= channel.members;
  		  if (role) membersEntry= channel[role];
		    return membersEntry.fetchedAll;
		}
  }
	return null;
}

export const selectLastMemberIdByChannelId = (channelId, role) => state => {
	if (channelId){
		const channel= selectChannelById(state, channelId);
		if (channel) {
		    let membersEntry= channel.members;
  		  if (role) membersEntry= channel[role];
		    return membersEntry.lastId;
		}
  }
	return null;
}

export const selectViewingChannelIds = ({ channels }) => channels.viewingChannelIds;
export const selectChannelInvite = ({ channels }) => channels.invite;
export const selectChannelApplication = ({ channels }) => channels.application;
export const selectChannelManageUser = ({ channels }) => channels.manageUser;

export const { addChannel, removeChannel, addChannels, setChannelRefresh,
               setChannelInvite, setChannelApplication, addViewingChannelId, 
               setChannelManageUser, removeViewingChannelId, setChannelUnread } = channelsSlice.actions;

export default channelsSlice.reducer;

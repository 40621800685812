import { Suspense } from 'react';
import lazyLoad from 'app/shared-components/util/lazyLoad';
import { memo } from 'react';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';

const ClientSelectorButton = lazyLoad(() => import('app/shared-components/client/ClientSelectorButton'));
const ReferralsAppToggleButton = lazyLoad(() => import('src/app/main/apps/referrals/ReferralsAppToggleButton'));
const SearchProviderButton = lazyLoad(() => import('app/theme-layouts/shared-components/SearchProviderButton'));
const ChannelSelectorButton = lazyLoad(() => import('app/shared-components/channel/ChannelSelectorButton'));
const ChatAppToggleButton = lazyLoad(() => import('src/app/main/apps/chat/ChatAppToggleButton'));
const ContactsAppToggleButton = lazyLoad(() => import('src/app/main/apps/contacts/ContactsAppToggleButton'));
const NotificationPanelToggleButton = lazyLoad(() => import('app/shared-components/layout/notificationPanel/NotificationPanelToggleButton'));
const UserMenu = lazyLoad(() => import('../../shared-components/UserMenu'));


function ProviderToolbar({isMobile, user}) {

  return (
      <>

    {!isMobile && (<>
    <SearchProviderButton isPublic={false}/>
    <ContactsAppToggleButton />
    
		<ReferralsAppToggleButton /> 
		
		<ClientSelectorButton /> 
		
		<ChannelSelectorButton type="organization" title="Organizations" 
                         icon={<FuseSvgIcon>heroicons-outline:building-office</FuseSvgIcon>}
                     />
                     
         <ChannelSelectorButton type="team" title="Teams" 
                         icon={<FuseSvgIcon>material-outline:people_outline</FuseSvgIcon>}
                     />

        <ChannelSelectorButton type="group" title="Groups" 
                         icon={<FuseSvgIcon>heroicons-outline:user-group</FuseSvgIcon>}
                     />
    </>)}
        <ChatAppToggleButton /> 

        <NotificationPanelToggleButton />

        <UserMenu />
      </>
  );
}

export default memo(ProviderToolbar);

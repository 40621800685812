import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { addChannels, fetchMembersByChannelId, addChannel,
         leaveChannel, joinChannel, removeChannel, 
         setChannelRead, setChannelUnread, fetchChannel } from 'app/store/channelsSlice';
import { applyToChannel } from './channelApplicationsSlice';
import { apiGet, apiPut, apiDel, apiPost } from 'app/shared-components/util/restAPI';
import { selectIsLoading, startLoading, stopLoading} from 'app/store/utilSlice';
import { bulkGroupListCalls } from 'app/shared-components/util/bulkApi';
import { deleteClientTeam, createClientTeam } from './clientsSlice';
import { submitPost, addPost, deletePost, removePost } from './postSlice';
import { APIModel } from 'app/shared-components/channel/GroupModel';
import { subscribeToChannel } from './subscriptionSlice';
import sortComparer from 'app/shared-components/util/lastMessageSortComparer';

const emptyArray= [];


export const getGroups = createAsyncThunk(
  'groups/getGroups',
  async ({type, bulk, closeSnackbar, enqueueSnackbar}, { dispatch, getState }) => {
	try{
      dispatch(startLoading());
      const parentNav= type + 's';
	  const { user } = getState();
	  let channels= bulk; //pre-fetched via bulk api
	  if (!channels){
		  const apiName = 'CoreAPI';
		  const path = '/user/' + user.id + '/channelmember/channels?role=STARTS:' + type;
	      const options = {
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
		  channels= await apiGet(apiName, path, options);
	  }
      
	 if (type === 'team' || type === 'organization'){
		 channels= channels.map((channel) => { return {...channel, reverseSort: true}});
	 }
	 dispatch(addChannels(channels));
	 
	 //Use bulk load to get topics and members for the groups
	 //Do this async so it doesn't block the return of this thunk
	 bulkGroupListCalls({channels: channels}).then((bulk) => {
		 const bulkData= bulk.items;
		 for (let i in channels) {
			const channel= channels[i];
			const topics= bulkData[channel.id+'topics'];
			 if (type === 'organization'){
				 const refChannel= bulkData[channel.id+'refChannel'];
				 if (refChannel && refChannel.code == 200){
					 topics.body.push(refChannel.body);
				 }
			 }
			dispatch(getTopics({groupId: channel.id, type: type, enqueueSnackbar: enqueueSnackbar, closeSnackbar: closeSnackbar, bulk: topics.code == 200 ? topics.body : undefined}));
			const threads= bulkData[channel.id+'threads'];
			dispatch(getThreads({groupId: channel.id, type: type, bulk: threads.code == 200 ? threads.body : undefined}));
			const members= bulkData[channel.id+'members'];
			dispatch(fetchMembersByChannelId({channelId: channel.id, bulk: members.code == 200 ? members.body : undefined}));
		 	if (type === 'team'){
				dispatch(subscribeToChannel({channelId: channel.id, enqueueSnackbar: enqueueSnackbar, closeSnackbar: closeSnackbar}));
				const clients= bulkData[channel.id+'clients'];
				dispatch(getClients({groupId: channel.id, type: type, enqueueSnackbar: enqueueSnackbar, closeSnackbar: closeSnackbar, bulk: clients.code == 200 ? clients.body : undefined}));
			}
		 	else if (type === 'organization'){
				dispatch(subscribeToChannel({channelId: channel.id, enqueueSnackbar: enqueueSnackbar, closeSnackbar: closeSnackbar}));
			}
		 }
	 });
	 
     return channels;
   }finally{
	   dispatch(stopLoading());
   }
  }
)

export const getTopics = createAsyncThunk(
  'groups/getTopics',
  async (params, { dispatch, getState }) => {
	  const { groupId, type, bulk, closeSnackbar, enqueueSnackbar } = params;
	  let channels= bulk;
	  if (!channels){
		  const apiName = 'CoreAPI';
		  const path = '/channel/byparent/' + groupId + '?channelType=STARTS:topic';
	      const options = {
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		  channels= await apiGet(apiName, path, options);
	  }

	 
	 channels= channels.map((channel) => { return {...channel, parentType: type, reverseSort: type === 'team' || type === 'organization'}});
	 dispatch(addChannels(channels));

	 
	 if (type === 'team' || type === 'organization'){
		for (let i in channels) {
			const channel= channels[i];
			dispatch(subscribeToChannel({channelId: channel.id, enqueueSnackbar: enqueueSnackbar, closeSnackbar: closeSnackbar}));
		 } 
	 }

     return channels;
  }
)

export const getThreads = createAsyncThunk(
  'groups/getThreads',
  async (params, { dispatch, getState }) => {
	  const { groupId, type, bulk } = params;
	  let channels= bulk;
	  if (!channels){
		  const apiName = 'CoreAPI';
		  const path = '/channel/byroot/' + groupId + '?channelType=STARTS:thread';
	      const options = {
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		  channels= await apiGet(apiName, path, options);
	  }
	 
	 channels= channels.map((channel) => { return {...channel, parentType: type, reverseSort: type === 'team' || type === 'organization'}});
	 dispatch(addChannels(channels));
     return channels;
  }
)

export const getClients = createAsyncThunk(
  'groups/getClients',
  async (params, { dispatch, getState }) => {
	  const {groupId, bulk, closeSnackbar, enqueueSnackbar} = params;
	  let data= bulk;
	  if (!data){
		  const apiName = 'CoreAPI';
		  const path = '/channel/' + groupId + '/clientteam/pairs';
	      const options = {
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
		  data= await apiGet(apiName, path, options); 
	  }
	  
	  //Add channels for chat
	  const channels= data.map((item) => { return {id: item.clientTeam.channelId,
	                                               rootId: item.clientTeam.channelId,
	                                               unread: item.clientTeam.unread,
	                                               name: item.client.firstName + " " + item.client.lastName,
	                                               lastMessageTimestamp: item.clientTeam.lastMessageTimestamp,
	                                               clientId: item.client.id,
	                                               channelType: "client", 
	                                               reverseSort: true}});
	  dispatch(addChannels(channels));
	  for (let i in channels) {
		const channel= channels[i];
		dispatch(subscribeToChannel({channelId: channel.id, enqueueSnackbar: enqueueSnackbar, closeSnackbar: closeSnackbar}));
	  } 
	  
	  //Join the data pairs
	  return data.map((item) => {
		  return {...item.client, ...item.clientTeam };
	  })
  }
)


export const getGroup = createAsyncThunk(
  'groups/getGroup',
  async (params, { dispatch, getState }) => {
	try{
	  const { channelId, type, refresh, closeSnackbar, enqueueSnackbar } = params;
      const useState= type === "group" ? getState().groups.groups : getState().groups.teams;
	  const existingGroup= useState.entities[channelId];
	  if (existingGroup && !refresh) return null;
      dispatch(startLoading());
      const apiName = 'CoreAPI';
	  const path = '/channel/' + channelId;
      const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };

	 const channel= await apiGet(apiName, path, options);
	 
	 dispatch(addChannel({channel: {...channel, reverseSort: type === 'team' || type === 'organization'}, refresh: refresh}));
	 
	 //Use bulk load to get topics and members for the groups
	 //Do this async so it doesn't block the return of this thunk
	 bulkGroupListCalls({channels: [channel]}).then((bulk) => {
		const bulkData= bulk.items;
		const topics= bulkData[channel.id+'topics'];
		if (type === 'organization'){
			 const refChannel= bulkData[channel.id+'refChannel'];
			 if (refChannel && refChannel.code == 200){
				 topics.body.push(refChannel.body);
			 }
		 }
		dispatch(getTopics({groupId: channel.id, type: type, enqueueSnackbar: enqueueSnackbar, closeSnackbar: closeSnackbar, bulk: topics.code == 200 ? topics.body : undefined}));
		const members= bulkData[channel.id+'members'];
		dispatch(fetchMembersByChannelId({channelId: channel.id, bulk: members.code == 200 ? members.body : undefined}));
	 	if (type === 'team'){
			dispatch(subscribeToChannel({channelId: channel.id, enqueueSnackbar: enqueueSnackbar, closeSnackbar: closeSnackbar}));
			const clients= bulkData[channel.id+'clients'];
			dispatch(getClients({groupId: channel.id, type: type, enqueueSnackbar: enqueueSnackbar, closeSnackbar: closeSnackbar, bulk: clients.code == 200 ? clients.body : undefined}));
		}
		else if (type === 'organization'){
			dispatch(subscribeToChannel({channelId: channel.id, enqueueSnackbar: enqueueSnackbar, closeSnackbar: closeSnackbar}));
		}
	 });
	 
     return channel;
   }finally{
	   dispatch(stopLoading());
   }
  }
)


export const addGroup = createAsyncThunk(
  'groups/addGroup',
  async (group, { dispatch, getState }) => {
	 const entity= group;
     const resp= await dispatch(createChannelEntity({entity: entity, reverseSort: group.type === 'team' || group.type === 'organization'}));
     if (resp.error) throw new Error(resp.error.message);
	 else {
		 const channel= resp.payload;
	     dispatch(fetchMembersByChannelId({channelId: channel.id}));
	     return channel;
	 }
  }
);

export const createChannelEntity = createAsyncThunk(
  "groups/createChannelEntity",
  async ({entity, reverseSort, parentType}, { dispatch, getState }) => {
    const apiName = 'CoreAPI';
	  const path = '/channelentity';
      const options = {
		  body: entity,
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };

	  //Store channel data
	  const data= await apiPut(apiName, path, options);
	  const out= await dispatch(fetchChannel({channelId: data.channelId, reverseSort: reverseSort, parentType: parentType}));
	  if (out.error) throw new Error(out.error.message);
	  else return out.payload;
  }
);

export const removeGroup = createAsyncThunk(
  'groups/removeGroup',
  async ({id, type}, { dispatch, getState }) => {
	  const resp= await dispatch(deleteChannelEntity(id));
	  if (resp.error) throw new Error(resp.error.message);
	  else return id;
  }
);

export const removeGroupFromStore = createAsyncThunk(
  'groups/removeGroupFromStore',
  async ({id, type}, { dispatch, getState }) => {
	  const resp= await dispatch(deleteChannelEntity(id));
	  if (resp.error) throw new Error(resp.error.message);
	  else return id;
  }
);


 export const deleteChannelEntity = createAsyncThunk(
  "groups/deleteChannelEntity",
  async (id, { dispatch, getState }) => {
	  const {channels}= getState(); 
      const apiName = 'CoreAPI';
      const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };
	  
	  const channel= channels.entities[id];
	  if (channel){
		 const key= getKey(channel.type, channel.entityId);
		 const path = '/channelentity/' + key;
	  	 const data= await apiDel(apiName, path, options); 
	  	 dispatch(removeChannel(id));
	  }
      return id;
  }
);

export const updateGroup = createAsyncThunk(
  'groups/updateGroup',
  async (group, { dispatch, getState }) => {
	  const channel= group;
	  const resp= await dispatch(updateChannelEntity({entity: channel, reverseSort: channel.type === 'team' || channel.type === 'organization'}));
	  if (resp.error) throw new Error(resp.error.message);
	  else return resp.payload;
  }
);

 export const updateChannelEntity = createAsyncThunk(
  "groups/updateChannelEntity",
  async ({entity, reverseSort, parentType}, { dispatch, getState }) => {
    const apiName = 'CoreAPI';
	  const path = '/channelentity/' + getKey(entity.type, entity.id);
      const options = {
		  body: entity,
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };
	  
	  //Store channel data
	  const data= await apiPut(apiName, path, options);
	  const out= await dispatch(fetchChannel({channelId: data.channelId, reverseSort: reverseSort, parentType: parentType, consistent: true}));
	  if (out.error) throw new Error(out.error.message);
	  return out.payload;
  }
);

export const leaveGroup = createAsyncThunk(
  'groups/leaveGroup',
  async (params, { dispatch, getState }) => {
	  await dispatch(leaveChannel(params.id));
	  dispatch(fetchChannel({channelId: params.id})); //Refresh channel 
      return params.id;
  }
);

export const addTopic = createAsyncThunk(
  'groups/addTopic',
  async ({ topic, type }, { dispatch, getState }) => {
    const resp = await dispatch(createChannelEntity({entity: topic, parentType: type, reverseSort: type === 'team' || type === 'organization'}));
    if (resp.error) throw new Error(resp.error.message);
    return resp.payload;
  }
);

export const updateTopic = createAsyncThunk(
  'groups/updateTopic',
  async ({ topic, type }, { dispatch, getState }) => {
	  const resp= await dispatch(updateChannelEntity({entity: topic, parentType: type, reverseSort: type === 'team' || type === 'organization'} ));
      if (resp.error) throw new Error(resp.error.message);	  
	  return resp.payload;
  }
);

export const removeTopic = createAsyncThunk(
  'groups/removeTopic',
  async (params, { dispatch, getState }) => {
	  const { topicId, type} = params;
	  const resp= await dispatch(deleteChannelEntity(topicId));
	  if (resp.error) throw new Error(resp.error.message);	  
      return topicId;
  }
);


export const leaveTopic = createAsyncThunk(
  'groups/leaveTopic',
  async (params, { dispatch, getState }) => {
	  const { topicId } = params;
	  dispatch(leaveChannel(topicId))
      return topicId;
  }
);

export const joinGroup = createAsyncThunk(
  'groups/joinGroup',
  async (params, { dispatch, getState }) => {
	  const {id, type, role, enqueueSnackbar, closeSnackbar}= params;
	  //Store channel data
	  const useRole= role ? role : 'member';
	  let resp= await dispatch(joinChannel({channelId: id, role: useRole}));
	  if (resp.error) throw new Error(resp.error.message);	  
	  resp= await dispatch(getGroup({channelId: id, type: type, refresh: true, enqueueSnackbar: enqueueSnackbar, closeSnackbar: closeSnackbar}));
      if (resp.error) throw new Error(resp.error.message);	  
      return id;
  }
);


export const applyToGroup = createAsyncThunk(
  'groups/applyToGroup',
  async (params, { dispatch, getState }) => {
	  const {id, type}= params;
	  //Store channel data
	  let resp= await dispatch(applyToChannel({channelId: id, type: type}));
	  if (resp.error) throw new Error(resp.error.message);	  
      return id;
  }
);

const getKey= (type, id) => {
	const model= type === 'group' ? 'Group' :
			     type === 'team' ? 'Team' :
			     type === 'organization' ? 'Organization' :
			     type === 'support' ? 'SupportCase' :
			     type === 'topic' ? 'Topic' : undefined;
	return model + ":" + id;		     
}


const groupsAdapter = createEntityAdapter({});
const topicsAdapter = createEntityAdapter({});
const clientsAdapter = createEntityAdapter({});
const fullClientsAdapter = createEntityAdapter({});

export const newGroup= (data) => {
	return { id: data.id, unread: data.unread, topicsUnread: false, threadsUnread: false,
	        clientsUnread: false,  type: data.channelType, name: data.entity?.name,
	        topics: topicsAdapter.getInitialState({status: "idle"}),
	        threads: topicsAdapter.getInitialState({status: "idle"}),
	        clients: clientsAdapter.getInitialState({status: "idle"}), //just maps channel ids and unread status
	        fullClients: fullClientsAdapter.getInitialState({status: "idle"})}; //has full client info
}

export const newTopic= (data) => {
	const out= { id: data.id, parentId: data.parentId, unread: data.unread, type: 'topic', name: data.entity?.name};
	return out;
}

export const newThread= (data) => {
	return { id: data.id, parentId: data.rootId, postId: data.parentPostId, immediateParentId: data.parentId, unread: data.unread, type: 'thread'};
}

export const newClient= (data) => {
	return { id: data.channelId, parentId: data.teamId, clientId: data.id, unread: data.unread, type: 'client'};
}

const getEntities= (state, action) => {
	const type= action.meta.arg.type ? action.meta.arg.type : 'group';
	const useState= stateType(state, type);
	return useState;
}

const groupsSlice = createSlice({
  name: 'groups',
  initialState: {
	  all: groupsAdapter.getInitialState(),
	  groups: groupsAdapter.getInitialState({unread: false, selectedId: null, selectedTab: 0}),
	  teams: groupsAdapter.getInitialState({unread: false, selectedId: null, selectedTab: 0}),
	  organizations: groupsAdapter.getInitialState({unread: false, selectedId: null, selectedTab: 0}),
  },
  reducers: {
    setSelectedGroupId: {
      reducer: (state, action) => {
        state.groups.selectedId = action.payload;
      }
    },
    setSelectedGroupTab: {
      reducer: (state, action) => {
        state.groups.selectedTab = action.payload;
      }
    },
    setSelectedTeamId: {
      reducer: (state, action) => {
        state.teams.selectedId = action.payload;
      }
    },
    setSelectedTeamTab: {
      reducer: (state, action) => {
        state.teams.selectedTab = action.payload;
      }
    },
    setSelectedOrgId: {
      reducer: (state, action) => {
        state.organizations.selectedId = action.payload;
      }
    },
    setSelectedOrgTab: {
      reducer: (state, action) => {
        state.organizations.selectedTab = action.payload;
      }
    },
    setSelectedIdByType: {
      reducer: (state, action) => {
		const {type, id} = action.payload;
		const ref= type + "s";
        state[ref].selectedId = id;
      }
    },
    setSelectedTabByType: {
      reducer: (state, action) => {
		const {type, tab} = action.payload;
		const ref= type + "s";
        state[ref].selectedTab = tab;
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(updateGroup.fulfilled, (state, action) => {
	  //handled in channelSlice via dispatch
    })
    .addCase(updateTopic.fulfilled, (state, action) => {
	  //handled in channelSlice via dispatch
    })
    .addCase(setChannelRead.fulfilled, (state, action) => {
      const channelId = action.payload;
      const channelEntry = state.all.entities[channelId];
      if (channelEntry) {
		 if (!channelEntry.unread) return; //No need to do anything if channel is already read
         channelEntry.unread= false;
         let useState= null;
         if (channelEntry.parentId){
			  const parentEntry = state.all.entities[channelEntry.parentId];
              if (parentEntry){
				  useState= stateType(state, parentEntry.type);
				  const groupEntry = useState.entities[parentEntry.id]; 
				  if (groupEntry) {
					  if (channelEntry.type === 'topic'){
						 const topicsEntry= groupEntry.topics;
					  	 parentEntry.topicsUnread= isAnyUnread(state, topicsEntry.ids);
					  }else if (channelEntry.type === 'thread'){
						 const threadsEntry= groupEntry.threads;
					  	 parentEntry.threadsUnread= isAnyUnread(state, threadsEntry.ids);
					  }else{
						  const clientsEntry= groupEntry.clients;
					      parentEntry.clientsUnread= isAnyUnread(state, clientsEntry.ids);
					  }
			      }
			  }
		 }else{
		    useState= stateType(state, channelEntry.type);
		 }
		 if (useState) useState.unread= isAnyUnread(state, useState.ids);
      }
    })
    .addCase(setChannelUnread, (state, action) => {
      const channelId = action.payload;
      const channelEntry = state.all.entities[channelId];
      if (channelEntry) {
		 if (channelEntry.unread) return; //No need to do anything if channel is already uread
         channelEntry.unread= true;
         let useState= null;
         if (channelEntry.parentId){
			  const parentEntry = state.all.entities[channelEntry.parentId];
              if (parentEntry){
				  useState= stateType(state, parentEntry.type);
				  if (channelEntry.type === 'topic'){
					 parentEntry.topicsUnread= true;
				  }else if (channelEntry.type === 'thread'){
					 parentEntry.threadsUnread= true;
				  }else{
				     parentEntry.clientsUnread= true;
				  }
			  }	
		 }else{
		    useState= stateType(state, channelEntry.type);
		 }
		 useState.unread= true;
      }
    })
    .addCase(addGroup.fulfilled, (state, action) => {
	  const useState= getEntities(state, action);
      groupsAdapter.addOne(useState, newGroup(action.payload));
      groupsAdapter.addOne(state.all, newGroup(action.payload));
    })
    .addCase(addTopic.fulfilled, (state, action) => {
	  const useState= getEntities(state, action);
	  const groupId = action.payload.parentId;
      const groupEntry = useState.entities[groupId];
      if (groupEntry) {
		  const topicsEntry= groupEntry.topics;
		  topicsAdapter.addOne(topicsEntry, newTopic(action.payload));  
		  topicsAdapter.addOne(state.all, newTopic(action.payload));  
      }
    })
    .addCase(removeGroup.fulfilled, (state, action) => {
	  const useState= getEntities(state, action);
      groupsAdapter.removeOne(useState, action.payload);
      useState.selectedId= null;
      useState.unread= isAnyUnread(state, useState.ids);
    })
    .addCase(removeTopic.fulfilled, (state, action) => {
	  const useState= getEntities(state, action);
      const groupId = action.meta.arg.groupId;
      const groupEntry = useState.entities[groupId];
      if (groupEntry) {
		  const topicsEntry= groupEntry.topics;
		  topicsAdapter.removeOne(topicsEntry, action.payload);
		  const entry= state.all.entities[groupId];
		  if (entry) entry.topicsUnread= isAnyUnread(state, topicsEntry.ids);;
		  useState.unread= isAnyUnread(state, useState.ids);
      }
    })
    .addCase(leaveGroup.fulfilled, (state, action) => {
	  const useState= getEntities(state, action);
      groupsAdapter.removeOne(useState, action.payload);
      state.selectedId= null;
      useState.unread= isAnyUnread(state, useState.ids);
    })
    .addCase(getGroups.fulfilled, (state, action) => {
	  const useState= getEntities(state, action);
	  const groupEntries = action.payload.map(group => {
        return newGroup(group);
      });
      groupsAdapter.setAll(useState, groupEntries);
      groupsAdapter.setMany(state.all, groupEntries);
      useState.unread= isAnyUnread(state, useState.ids);
    })
    .addCase(getGroup.fulfilled, (state, action) => {
	  const useState= getEntities(state, action);
	  if (action.payload){
		 const group= newGroup(action.payload);
		 groupsAdapter.setOne(useState, group);
		 groupsAdapter.setOne(state.all, group);
		 if (group.unread) useState.unread= true;
	  }
	  
    })
    .addCase(getTopics.fulfilled, (state, action) => {
	  const useState= getEntities(state, action);
      const groupId = action.meta.arg.groupId;
      const groupEntry = useState.entities[groupId];
      if (groupEntry) {
		  const topicsEntry= groupEntry.topics;
		  topicsEntry.status= "succeeded";
		  const topicEntries = action.payload.map(topic => {
	        return newTopic(topic);
	      });
		  topicsAdapter.setAll(topicsEntry, topicEntries); 
		  topicsAdapter.setMany(state.all, topicEntries);
		  if (isAnyUnread(state, topicsEntry.ids)) {
			  useState.unread= true;
			  const entry= state.all.entities[groupId];
			  if (entry) entry.topicsUnread= true;
		  }
      }
    })
    .addCase(getThreads.fulfilled, (state, action) => {
	  const useState= getEntities(state, action);
      const groupId = action.meta.arg.groupId;
      const groupEntry = useState.entities[groupId];
      if (groupEntry) {
		  const threadsEntry= groupEntry.threads;
		  threadsEntry.status= "succeeded";
		  const threadEntries = action.payload.map(thread => {
	        return newThread(thread);
	      });
		  topicsAdapter.setAll(threadsEntry, threadEntries); 
		  topicsAdapter.setMany(state.all, threadEntries);
		  if (isAnyUnread(state, threadsEntry.ids)) {
			  useState.unread= true;
			  const entry= state.all.entities[groupId];
			  if (entry) entry.threadsUnread= true;
		  }
      }
    })
    .addCase(getClients.fulfilled, (state, action) => {
	  const useState= getEntities(state, action);
      const groupId = action.meta.arg.groupId;
      const groupEntry = useState.entities[groupId];
      if (groupEntry) {
		  const clientsEntry= groupEntry.clients;
		  clientsEntry.status= "succeeded";
		  const clientEntries = action.payload.map(client => {
	        return newClient(client);
	      });
	      fullClientsAdapter.setAll(groupEntry.fullClients, action.payload); 
		  clientsAdapter.setAll(clientsEntry, clientEntries); 
		  clientsAdapter.setMany(state.all, clientEntries); 
		  if (isAnyUnread(state, clientsEntry.ids)) {
			  useState.unread= true;
			  const entry= state.all.entities[groupId];
			  if (entry) entry.clientsUnread= true;
		  }
      }
    })
    .addCase(deleteClientTeam.fulfilled, (state, action) => {
      const useState= state.teams;
	  const {clientId, channelId, teamId} = action.payload;
      const groupEntry = useState.entities[teamId];
      if (groupEntry) {
		  const clientsEntry= groupEntry.clients;
		  fullClientsAdapter.removeOne(groupEntry.fullClients, clientId); 
		  clientsAdapter.removeOne(clientsEntry, channelId);  
		  clientsAdapter.removeOne(state.all, channelId);  
      }	        
    })
    .addCase(submitPost.fulfilled, (state, action) => {
      const parentPostId = action.meta.arg.parentPostId;
      if (!parentPostId) return; //Only process comments to ensure threads are handled
      const channelId = action.meta.arg.channelId;
      const rootChannelId = action.meta.arg.rootChannelId;
      const channelType= action.meta.arg.channelType;
      const channelEntry = state.all.entities[channelId];
      
      //If there is no thread entry for this channel, need to create one
      if (!channelEntry) {
      	 const useState= stateType(state, channelType);
      	 const rootEntry = useState.entities[rootChannelId];
      	 if (rootEntry){
      	 	const threadsEntry= rootEntry.threads;
	        const thread= newThread({id: channelId, rootId: rootChannelId, parentPostId: parentPostId, unread: false});
		    topicsAdapter.addOne(threadsEntry, thread); 
		    topicsAdapter.addOne(state.all, thread);
      	 }
      }
    })
    .addCase(addPost, (state, action) => {
      const {post, subscriptionChannelId}= action.payload;
      const parentPostId = post.parentPostId;
      if (!parentPostId) return; //Only process comments to ensure threads are handled
      const channelId = post.channelId;
      const parentChannelId= subscriptionChannelId;
      const channelEntry = state.all.entities[channelId];
      
      //If there is no thread entry for this channel, need to create one
      if (!channelEntry) {
      	 const parentEntry= state.all.entities[parentChannelId];
      	 if (parentEntry.parentId) parentEntry= state.all.entities[parentEntry.parentId]; //get root group if necessary
      	 const useState= stateType(state, parentEntry.type);
      	 const rootEntry = useState.entities[parentEntry.id];
      	 if (rootEntry){
      	 	const threadsEntry= rootEntry.threads;
	        const thread= newThread({id: channelId, rootId: parentEntry.id, parentPostId: parentPostId, unread: false});
		    topicsAdapter.addOne(threadsEntry, thread); 
		    topicsAdapter.addOne(state.all, thread);
      	 }
      }
    })
    .addCase(deletePost.fulfilled, (state, action) => {
      const commentChannelId = action.meta.arg.commentChannelId;
      if (!commentChannelId) return;
      const channelEntry = state.all.entities[commentChannelId];
      
      //If there is a thread entry for this channel, need to remove
      if (channelEntry) {
      	 const rootChannelId = action.meta.arg.rootChannelId;
         const channelType = action.meta.arg.channelType;
      	 const useState= stateType(state, channelType);
      	 const rootEntry = useState.entities[rootChannelId];
      	 if (rootEntry){
      	 	const threadsEntry= rootEntry.threads;
		    topicsAdapter.removeOne(threadsEntry, commentChannelId); 
		    topicsAdapter.removeOne(state.all, commentChannelId);
		    rootEntry.threadsUnread= isAnyUnread(state, threadsEntry.ids);
		    useState.unread= isAnyUnread(state, useState.ids);
      	 }
      }
    })
    .addCase(removePost, (state, action) => {
      const {post, subscriptionChannelId}= action.payload;
      const commentChannelId = post.commentChannelId;
      if (!commentChannelId) return;
      const channelId = post.channelId;
      const parentChannelId= subscriptionChannelId;
      const channelEntry = state.all.entities[channelId];
      
      //If there is a thread entry for this channel, need to remove
      if (channelEntry) {
         const parentEntry= state.all.entities[parentChannelId];
      	 if (parentEntry.parentId) parentEntry= state.all.entities[parentEntry.parentId]; //get root group if necessary
      	 const useState= stateType(state, parentEntry.type);
      	 const rootEntry = useState.entities[parentEntry.id];
      	 if (rootEntry){
      	 	const threadsEntry= rootEntry.threads;
		    topicsAdapter.removeOne(threadsEntry, commentChannelId); 
		    topicsAdapter.removeOne(state.all, commentChannelId);
		    rootEntry.threadsUnread= isAnyUnread(state, threadsEntry.ids);
		    useState.unread= isAnyUnread(state, useState.ids);
      	 }
      } 
    })
  },
});

const isAnyUnread= (state, ids) => {
	for(let i=0; i < ids.length; i++){
		const id= ids[i];
		const data= state.all.entities[id];
		if (data && (data.unread || data.topicsUnread || data.threadsUnread || data.clientsUnread)) return true;
	}
	return false;
}

const { selectAll, selectById } = groupsAdapter.getSelectors();

const {
  selectAll: selectTopics, selectById: selectTopicById
} = topicsAdapter.getSelectors(state => state.topics);

const {
  selectAll: selectThreads, selectById: selectThreadById
} = topicsAdapter.getSelectors(state => state.threads);

//Get the objects for the specified type
export const selectAllByType= (type) => (state) => {
	const useState= stateByType(state, type);
	return selectAll(useState);
}

//Get the objects for the specified type
export const selectCountByType= (type) => (state) => {
	const useState= stateByType(state, type);
	const entities= selectAll(useState);
	return entities.length;
}  

//Get the selectedId for the specified type
export const selectSelectedIdByType= (type) => (state) => {
	const useState= stateByType(state, type);
	return useState.selectedId;
}

//Get the selectedTab for the specified type
export const selectSelectedTabByType= (type) => (state) => {
	const useState= stateByType(state, type);
	return useState.selectedTab;
}  

export const selectAllGroupChannelsByType = createSelector(
	[(state, type) => {
		const useState= stateByType(state, type);
	    return selectAll(useState);
	}, 
	(state) => state.channels.entities],
	(groups, channels) =>{
		const out= [];
		for (let i in groups) {
		   const group= groups[i];
		   const chan= channels[group.id];
		   if (chan) out.push(chan);
		} 
		return out;
	}
)


//Get the objects for the specified type
export const selectUnreadByType= (type) => (state) => {
	const useState= stateByType(state, type);
	return useState.unread;
} 

export const selectByTypeAndId= (type, id) => (state) => {
	const useState= stateByType(state, type);
	return selectById(useState, id);
} 

export const selectEntryById= (id) => (state) => {
	return selectById(state.groups.all, id);
} 


export const selectTopicsByParentId = (type, parentId) => state => {
	const useState= stateByType(state, type);
    if (parentId){
		const group= selectById(useState, parentId);
		if (group){
			const topics= selectTopics(group);
		    return topics;
		}
	}
	return emptyArray;
}

export const selectThreadsByParentId = (type, parentId) => state => {
	return internalSelectThreadsByParentId(state, type, parentId);
}

const internalSelectThreadsByParentId = (state, type, parentId) => {
	const useState= stateByType(state, type);
    if (parentId){
		const group= selectById(useState, parentId);
		if (group){
			const threads= selectThreads(group);
		    return threads;
		}
	}
	return emptyArray;
}

const selectChannelEntities = (state) => {
	return state.channels.entities;
}

const internalSelectOrderedThreadsByParentId = createSelector([internalSelectThreadsByParentId, selectChannelEntities], (threads, entities) => {
   const withTimestamps= threads.map((thread) => {
   	 if (entities[thread.id]) return {...thread,  lastMessageTimestamp: entities[thread.id].lastMessageTimestamp };
   	 else return;
   	});
   	return withTimestamps.sort(sortComparer);
})



export const selectOrderedThreadsByParentId = (type, parentId) => state => {
	return internalSelectOrderedThreadsByParentId(state, type, parentId);
}

//Get the objects for the specified type
const stateByType= (state, type) => {
	return stateType(state.groups, type);
} 

//Get the objects for the specified type
const stateType= (state, type) => {
	const useState= type === 'organization' ? state.organizations : 
	                type === 'team' ? state.teams : state.groups;
	return useState;
} 

export const {
  selectAll: selectChannelClients, selectById: selectClientById
} = fullClientsAdapter.getSelectors(state => state.fullClients);

export const selectClientsByGroupId = (groupId) => state => {
	const useState= state.groups.teams;
    if (groupId){
		const group= selectById(useState, groupId);
		if (group){
			const clients= selectChannelClients(group);
		    return clients;
		}
	}
	return emptyArray;
}

export const selectChannelClientByIds = (groupId, clientId) => state => {
    const useState= state.groups.teams;
    if (groupId && clientId){
		const group= selectById(useState, groupId);
		if (group){
			const client= selectClientById(group, clientId);
		    return client;
		}
	}
	return null;
}


export const selectSelectedGroupId = (state) => selectSelectedIdByType('group')(state);
export const selectSelectedGroupTab = (state) => selectSelectedTabByType('group')(state);
export const selectSelectedTeamId = (state) => selectSelectedIdByType('team')(state);
export const selectSelectedTeamTab = (state) => selectSelectedTabByType('team')(state)
export const selectSelectedOrgId = (state) => selectSelectedIdByType('organization')(state);
export const selectSelectedOrgTab = (state) => selectSelectedTabByType('organization')(state);
export const { setSelectedGroupId, setSelectedGroupTab, setSelectedTeamId, setSelectedTeamTab, setSelectedOrgId, setSelectedOrgTab, setSelectedTabByType, setSelectedIdByType } = groupsSlice.actions;

export default groupsSlice.reducer;

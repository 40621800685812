import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { stopLoading, startLoading} from 'app/store/utilSlice';
import sortComparer from 'app/shared-components/util/lastMessageSortComparer';
import { isUnread } from 'app/shared-components/utils';
import { setChannelRead, setChannelUnread, addChannel } from './channelsSlice';
import { bulkApiGet, buildGetItem } from 'app/shared-components/util/bulkApi';
import { apiGet, apiPut, apiDel, apiPost } from 'app/shared-components/util/restAPI';

const emptyArray= [];


const orgReferralAdapter = createEntityAdapter({
});

const myOrgReferralAdapter = createEntityAdapter({
});

export const createOrganizationReferral = createAsyncThunk(
  "organizationReferrals/createOrganizationReferral",
  async ({organization, referral, message }, { dispatch, getState }) => {
	try{
	  dispatch(startLoading());
	  const orgChannelId= organization.channelId;
	  const referralId= referral.id;
	  const orgReferral= { referralId: referralId, message: message };
	  const url= '/channel/' + orgChannelId + '/organizationreferral';
	  const apiName = 'CoreAPI';
	  const path = url;
	  const options = {
		  headers: {},
		  body: orgReferral,
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };
	
	  let data= await apiPut(apiName, path, options);
	  const useOrg= { id: orgChannelId, channelType: 'organization', entity: organization };
	  return { referral: referral, organization: useOrg, organizationReferral: data,
	           createdAt: data.createdAt, id: useOrg.id + '-' + referral.id  }; 
	}finally{
		dispatch(stopLoading());
	}
  }
)

export const fetchOrganizationReferrals = createAsyncThunk(
  "organizationReferrals/fetchOrganizationReferrals",
  async ({orgChannelId, status }, { dispatch, getState }) => {
	try{
	  dispatch(startLoading());
	  let url= '/channel/' + orgChannelId + '/organizationreferral/pairs?status=STARTS:' + status;
	  const apiName = 'CoreAPI';
	  const path = url;
	  const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };
	
	  let data= await apiGet(apiName, path, options);
	  return data.map((pair) => { return { referral: pair.referral, organizationReferral: pair.organizationReferral, createdAt: pair.organizationReferral.createdAt, id: pair.referral.id }; });
	}finally{
		dispatch(stopLoading());
	}
  }
)

export const fetchMyOrganizationReferrals = createAsyncThunk(
  "organizationReferrals/fetchMyOrganizationReferrals",
  async ({bulk}, { dispatch, getState }) => {
	try{
	  let data= bulk;
	  if (!data){
		  dispatch(startLoading());
		  const url= '/channelentity/orgreferralsbycaller';
		  const apiName = 'CoreAPI';
		  const path = url;
		  const options = {
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
		
		  data= await apiGet(apiName, path, options);
	  }
	  return data.map((info) => { return { referral: info.referral, organization: info.organization, organizationReferral: info.organizationReferral,
	                                       createdAt: info.organizationReferral.createdAt, id: info.organization.id + '-' + info.referral.id  }; });
	}finally{
		dispatch(stopLoading());
	}
  }
)

export const closeInterest = createAsyncThunk(
	"organizationReferrals/closeInterest",
	async (params, { dispatch, getState }) => {
		dispatch(startLoading());
		try{
			const { organizationId, referralId } = params;
			const apiName = 'CoreAPI';
			const path = '/channel/' + organizationId + '/organizationreferral/' + referralId;
			const options = {
				body: {status: 'closedinterest'},
				headers: {},
				response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
			};

			let data= await apiPut(apiName, path, options);
			return data;
		}finally{
			dispatch(stopLoading());
		}
	}
)

const organizationReferralsSlice = createSlice({
  name: "organizationReferrals",
  initialState: { orgMap: {},
	  				shareReferralId: null,
                  myOrgReferrals: myOrgReferralAdapter.getInitialState() },  
  reducers: {
	  setShareOrgReferralId: (state, action) => {
		  state.shareReferralId= action.payload;
	  },
  },
  extraReducers: builder => {
    builder
    .addCase(fetchOrganizationReferrals.fulfilled, (state, action) => {
    	  const orgChannelId  = action.meta.arg.orgChannelId;
    	  let orgState= state.orgMap[orgChannelId];
    	  if (!orgState){
			  orgState= {};
			  orgState.open= orgReferralAdapter.getInitialState();
			  orgState.closed= orgReferralAdapter.getInitialState();
			  orgState.orginterest= orgReferralAdapter.getInitialState();
			  state.orgMap[orgChannelId]= orgState;
    	  }
		  const entityState= orgState[action.meta.arg.status];
		  orgReferralAdapter.setAll(entityState, action.payload);
    })
    .addCase(createOrganizationReferral.fulfilled, (state, action) => {
        myOrgReferralAdapter.addOne(state.myOrgReferrals, action.payload);
    })
    .addCase(fetchMyOrganizationReferrals.fulfilled, (state, action) => {
        myOrgReferralAdapter.setAll(state.myOrgReferrals, action.payload);
    })
		.addCase(closeInterest.fulfilled, (state, action) => {
			const organizationId = action.meta.arg.organizationId;
			const referralId = action.meta.arg.referralId;
			let orgState= state.orgMap[organizationId];
			if (orgState) {
				let entry= selectById(orgState.open, referralId);
				if (entry) orgReferralAdapter.removeOne(orgState.open, referralId);
				else{
					entry= selectById(orgState.orginterest, referralId);
					orgReferralAdapter.removeOne(orgState.orginterest, referralId);
				}
				if (entry) {
					orgReferralAdapter.addOne(orgState.closed, entry);
				}
			}
		})
  }
})


const { selectAll, selectById } = orgReferralAdapter.getSelectors();
const { selectAll: selectAllMy } = myOrgReferralAdapter.getSelectors(state => state.organizationReferrals.myOrgReferrals);

export const selectOrganizationReferralsByOrgAndStatus = (orgChannelId, status) => state => {
	if (orgChannelId) {
		const orgState= state.organizationReferrals.orgMap[orgChannelId];
		if (orgState) return selectAll(orgState[status]);
	}
	return emptyArray;
}

export const selectOrganizationReferralByOrgAndId = (orgChannelId, referralId) => state => {
	let out= null;
	if (orgChannelId) {
		const orgState= state.organizationReferrals.orgMap[orgChannelId];
		if (orgState) {
			out= selectById(orgState.open, referralId);
			if (!out) out= selectById(orgState.orginterest, referralId);
			if (!out) out= selectById(orgState.closed, referralId);
		}
	}
	return out;
}

export const selectMyOrganizationReferrals= (state) => {
	return selectAllMy(state);
}

export const selectMyOrganizationReferralsByOrgId = (orgChannelId) => state => {
	if (orgChannelId) {
		const all= selectAllMy(state);
		return all.filter(ref => ref.id == orgChannelId)
	}
	return emptyArray;
}

export const selectShareOrgReferralId = ({ organizationReferrals }) => organizationReferrals.shareReferralId;

export const {  setShareOrgReferralId } = organizationReferralsSlice.actions;

export default organizationReferralsSlice.reducer;
